import './App.scss';
import { Navigate, Route, Routes, useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Home from './pages/Home/Home';
import Packages from './pages/Packages/package2';
import Footer from './component/Footer/Footer';
import ContactUs from './pages/Contact us/ContactUs';
import Location from './pages/Location/Location';
import PackageDetails from './pages/Packages/PackageDetails';
import Services from './pages/Services/Services';
import Reports from './pages/Forms/Report2';
import Booking from './pages/Forms/Booking2';
import Withdrawl from './pages/Forms/Withdrawl2';
import MedicalInformation from './pages/Medical Information/MedicalInformation';
import Opinion from './pages/Opinion/Opinion';

import OfferPage from './pages/Sales/OfferPage';
import AboutPage from './pages/About/AboutPage';
import Navbar from './component/Navbar/NavbarT';

import Admin from './pages/Admin/Admin';
import AdminPackageCategoryForm from './component/Admin/AdminPackaeCategory';
import AddPackage from './component/Admin/AddPackage';
import AddInfo from './component/Admin/AddInfo';
import AddOffer from './component/Admin/AddOffer';
import AddOpinion from './component/Admin/AdminOpinion';
import PackageManagement from './component/Admin/PackageManagement';
import OfferManagement from './component/Admin/OfferManagement';
import InformationManagement from './component/Admin/InformationManagement';
import OpinionManagement from './component/Admin/Opinionmangement';
import PrivacyPolicy from './component/PrivacyPolicy';

function App() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    document.title = "Alshifa Laboratory - Khorfakkan";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  
  return (
    <div className="App ">
    
      {/* <NavBar/> */}
      <Navbar/>
      <div className={isHomePage ? 'font-arabic' : "mt-[18vh] min-h-[80vh] font-arabic"} dir={i18n.language === "ar" ? "rtl" : "ltr"}>
        <Routes>
          <Route path="/" element={<Home />} />


          <Route path="/services" element={<Services />} />
          <Route path="/reports" element={<Reports />} />  
          <Route path="/withdraw" element={<Withdrawl />} /> 
          {/* package */}
          <Route path="/package/*" element={<Packages />}/> 
          <Route path="/offer/*" element={<OfferPage/>}/> 
          <Route path="/package/detail/*" element={<PackageDetails />} />
          <Route path="/package/booking" element={<Booking />} /> 

          <Route path="/contact" element={<ContactUs />} /> 
          <Route path="/location" element={<Location />} />
          
          <Route path="/information" element={<MedicalInformation />} />
          <Route path="/aboutPage/*" element={<AboutPage />} />
          <Route path="/opinion" element={<Opinion />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
 
          
          <Route path="/admin" element={<Admin />}>
          <Route index element={<Navigate to="/admin/packages" replace />} />
            <Route path="addpackages/:packageId?" element={<AddPackage />} />
            <Route path="addoffer/:offerId?" element={<AddOffer />} />
            <Route path="addinfo/:infoId?" element={<AddInfo />} />
            <Route path="addOpinion/:opinionId?" element={<AddOpinion />} />
            <Route path="package-category/:catId?" element={<AdminPackageCategoryForm />} />
            <Route path="packages" element={<PackageManagement />} />
            <Route path="offer" element={<OfferManagement />} />
            <Route path="information" element={<InformationManagement />} />
            <Route path="opinions" element={<OpinionManagement />} />
          </Route>
        </Routes>
      </div>
      <Footer/>
    </div>

  );
}

export default App;
