// src/pages/Services/Services.js
import React from 'react';
import ServiceItem from '../../component/Services/ServiceItem'; // Adjust path if necessary
import { useFetch } from '../../hooks/useFetch';
import SpinnerLoading from '../../component/Loading/SpinnerLoading';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    
    const services = t("services.service", { returnObjects: true });

    return (
      <div className="min-h-screen bg-gray-50">
        <header className="text-center py-4 transition-opacity duration-500">
          <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
            {t('services.title')}
          </h1>
          <h2 className="text-xl text-gray-600 mt-2 opacity-80 transition-opacity duration-500">
            {t('services.description')}
          </h2>
        </header>

        <div className="container mx-auto px-4 py-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
          {services && services.length > 0 ? (
            services.map((service) => (
              <ServiceItem key={service.id} service={service} />
            ))
          ) : (
            <div>No services available at the moment.</div>
          )}
        </div>
      </div>
    );
};

export default Services;
