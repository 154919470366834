import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook

const getMonthName = (monthIndex) => {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return monthNames[monthIndex];
};

const WeeklyInfo = ({ weeklyInfo, activeWeek, setActiveWeek }) => {
  const { t, i18n } = useTranslation(); // Access translation hook
  const currentMonthName = getMonthName(new Date().getMonth());

  // State for selected month, initialized with the current month
  const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
  // Handle month change
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setActiveWeek(1); // Reset week to 1 whenever month changes
  };

  // Filter weekly info by selected month and week
  const filteredInfo = weeklyInfo.find(
    (info) => info.month === selectedMonth && info.week === activeWeek
  );

  return (
    <div className="max-w-6xl mx-auto space-y-8 px-4 py-8">
<div className="flex flex-col sm:flex-row justify-center items-center mb-8 space-y-4 sm:space-y-0 sm:space-x-6">
  {/* Month selector dropdown */}
  <div className="relative w-full sm:w-auto">
    <select
      className="w-full sm:w-48 px-4 py-2 border border-gray-300 bg-white rounded-lg focus:ring-2 focus:ring-secondColor transition ease-in-out duration-300 appearance-none text-gray-700 shadow-sm hover:bg-gray-100"
      value={selectedMonth}
      onChange={handleMonthChange}
    >
      {/* Months with localized names */}
      <option value="January">{t('months.january')}</option>
      <option value="February">{t('months.february')}</option>
      <option value="March">{t('months.march')}</option>
      <option value="April">{t('months.april')}</option>
      <option value="May">{t('months.may')}</option>
      <option value="June">{t('months.june')}</option>
      <option value="July">{t('months.july')}</option>
      <option value="August">{t('months.august')}</option>
      <option value="September">{t('months.september')}</option>
      <option value="October">{t('months.october')}</option>
      <option value="November">{t('months.november')}</option>
      <option value="December">{t('months.december')}</option>
    </select>
  </div>

  {/* Week selection buttons */}
  <div className="flex flex-wrap  justify-center gap-[10px]">
    {[1, 2, 3, 4].map((week) => (
      <button
        key={week}
        className={` px-4 py-2 sm:px-6 sm:py-2 font-semibold rounded-full transition-all duration-300 ease-in-out shadow ${
          activeWeek === week
            ? 'bg-primeColor text-white shadow-md'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        }`}
        onClick={() => setActiveWeek(week)}
      >
        {t('medicalInformation.weeklyTab')} {week}
      </button>
    ))}
  </div>
</div>



      {/* Weekly info display */}
      <div className="bg-white p-8 rounded-lg shadow-md max-w-3xl mx-auto transition-all duration-300 relative overflow-hidden">
        {filteredInfo ? (
          <div className="space-y-6">
            {/* Label showing the availability of info */}
            <span className="absolute top-4 right-4 text-sm bg-primeColor text-white px-2 py-1 rounded">
              {t('medicalInformation.availableInfo')}
            </span>
            {/* Title and description based on selected language */}
            <h2 className="text-3xl font-semibold text-primeColor mb-4">
              {i18n.language === 'ar' ? filteredInfo.title_ar : filteredInfo.title_en}
            </h2>
            <p className="text-gray-700 text-lg leading-relaxed">
              {i18n.language === 'ar' ? filteredInfo.description_ar : filteredInfo.description_en}
            </p>
          
          </div>
        ) : (
          // Fallback message if no info is available for the selected week/month
          <p className="text-gray-500 text-center">
            {t('medicalInformation.noWeeklyInfo')}
          </p>
        )}
        <div className="absolute inset-0 bg-gradient-to-r from-primeColor to-transparent opacity-20 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    </div>
  );
};

export default WeeklyInfo;
