import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="text-start bg-gray-50 text-gray-900 px-4 py-8 lg:px-16 lg:py-16">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
        <p className="text-sm mb-4">Last updated: October 10, 2024</p>
        <p className="mb-6">
          This Privacy Policy describes Our policies and procedures on the collection, use, and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You.
        </p>
        <p className="mb-6">
          We use Your Personal data to provide and improve the Service. By using the Service, You
          agree to the collection and use of information in accordance with this Privacy Policy.
        </p>

        {/* Interpretation and Definitions */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Interpretation and Definitions</h2>
        <h3 className="text-xl font-medium mt-6 mb-4">Interpretation</h3>
        <p className="mb-6">
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </p>
        <h3 className="text-xl font-medium mt-6 mb-4">Definitions</h3>
        <p className="mb-4">For the purposes of this Privacy Policy:</p>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li>
            <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party.
          </li>
          <li>
            <strong>Company</strong> (referred to as "We", "Us", or "Our" in this Agreement) refers to Alshifa Medical Center Lap.
          </li>
          <li>
            <strong>Cookies</strong> are small files placed on Your device containing browsing history information.
          </li>
          <li>
            <strong>Device</strong> means any device that can access the Service such as a computer, cellphone, or tablet.
          </li>
          <li>
            <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
          </li>
          <li>
            <strong>Service</strong> refers to the Website.
          </li>
          <li>
            <strong>Service Provider</strong> means any person who processes the data on behalf of the Company.
          </li>
          <li>
            <strong>Usage Data</strong> refers to data collected automatically from the use of the Service or the Service infrastructure.
          </li>
        </ul>

        {/* Collecting and Using Your Personal Data */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Collecting and Using Your Personal Data</h2>
        <h3 className="text-xl font-medium mt-6 mb-4">Types of Data Collected</h3>

        <h4 className="text-lg font-medium mt-4 mb-2">Personal Data</h4>
        <p className="mb-6">
          While using Our Service, We may ask You to provide Us with certain personally identifiable
          information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
        </p>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Usage Data</li>
        </ul>

        <h4 className="text-lg font-medium mt-4 mb-2">Usage Data</h4>
        <p className="mb-6">
          Usage Data is collected automatically when using the Service. This may include information such as your device's IP address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, and other diagnostic data.
        </p>

        {/* Tracking Technologies and Cookies */}
        <h3 className="text-xl font-medium mt-6 mb-4">Tracking Technologies and Cookies</h3>
        <p className="mb-6">
          We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
        </p>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li><strong>Cookies</strong> or Browser Cookies. A cookie is a small file placed on Your Device.</li>
          <li><strong>Web Beacons</strong>. Certain sections of our Service and emails may contain small electronic files known as web beacons that permit the Company to count users who have visited pages or opened emails.</li>
        </ul>

        {/* Use of Your Personal Data */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Use of Your Personal Data</h2>
        <p className="mb-6">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
          <li><strong>To manage Your Account</strong>: to manage Your registration as a user of the Service.</li>
          <li><strong>For the performance of a contract</strong>: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased.</li>
          <li><strong>To contact You</strong>: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication.</li>
          <li><strong>To manage Your requests</strong>: To attend and manage Your requests to Us.</li>
        </ul>

        {/* Retention of Your Personal Data */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Retention of Your Personal Data</h2>
        <p className="mb-6">
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations.
        </p>

        {/* Your Rights */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Your Rights</h2>
        <p className="mb-6">
          You have the right to access, update, or delete the information We have on You. Whenever made possible, you can update Your Personal Data directly within Your account settings section. You also have the right to be informed, to restrict processing, and to data portability.
        </p>

        {/* Security of Your Personal Data */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Security of Your Personal Data</h2>
        <p className="mb-6">
          The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
        </p>

        {/* Contact Us */}
        <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
        <p className="mb-6">
          If you have any questions about this Privacy Policy, You can contact us:
        </p>
        <ul className="list-disc list-inside mb-6 space-y-2">
          <li>By email: Asmc.lab@gmail.com</li>
          <li>
            By visiting this page on our website: 
            <a href="https://www.alshifalab.net" className="text-blue-600 underline"> https://www.alshifalab.net</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
