import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Table from './Table'; // Import the reusable Table component
import { useFetch } from '../../hooks/useFetch';
import i18n from '../../i18n';
import TableLoading from '../Loading/TableLoading';
import API_ENDPOINTS from '../../api';
 
const PackageManagement = () => { 
  const [packages, setPackages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const { data: packagesData, loading, error: PackagesError } = useFetch(API_ENDPOINTS.PACKAGES.GET_ALL);
  const { data: categoriesData, loading: CLoading, error: CategoriesError } = useFetch(API_ENDPOINTS.Category.GET_ALL);

  // Update the categories when categoriesData is fetched
  useEffect(() => {
    if (categoriesData && Array.isArray(categoriesData.categories)) {
      setCategories(categoriesData.categories);
    }
  }, [categoriesData]);

  // Update the packages and filteredPackages when packagesData is fetched
  useEffect(() => {
    if (packagesData && Array.isArray(packagesData.packages)) {
      setPackages(packagesData.packages);      
      setFilteredPackages(packagesData.packages); // Initialize with all packages
    }
  }, [packagesData]);

  // Filter packages based on the selected category
  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);
    if (selected) {
      setFilteredPackages(packages.filter(pkg => pkg.categoryId._id === selected));
    } else {
      setFilteredPackages(packages); // Reset to all packages if no category is selected
    }
  }; 

  const handleUpdate = (id) => {
    console.log('Update package:', id);
    // Navigate to the update form with the package ID
    navigate(`/admin/addpackages/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(API_ENDPOINTS.PACKAGES.DELETE(id));
      // After deletion, refetch the packages or filter out the deleted one
      setPackages(prevPackages => prevPackages.filter(pkg => pkg._id !== id));
      setFilteredPackages(prevPackages => prevPackages.filter(pkg => pkg._id !== id));
    } catch (error) {
      console.error('Failed to delete package:', error);
    }
  };

  if (CLoading || loading) return <div className="text-center"><TableLoading/></div>;
  if (PackagesError) return <div className="text-red-500">Error: {PackagesError.message}</div>;
  if (CategoriesError) return <div className="text-red-500">Error fetching categories: {CategoriesError.message}</div>;

  const specificKeys = ['name_ar', 'price', 'onSale', 'category', 'tests', 'priceSale', 'testsSale', 'salePercent'];

  // Filter headers that have valid values for the specific keys
  const packageHeaders = specificKeys.filter(key => 
    packages.some(pack => pack[key] !== null && pack[key] !== '' && pack[key] !== undefined)
  );

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md">
      <h2 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">Admin Package Management</h2>
      <div className="mb-4 flex gap-[30px] items-end justify-between">
        <div>
          <label htmlFor="categorySelect" className="block mb-2 text-start font-medium">Select Category:</label>
          <select 
            id="categorySelect" 
            value={selectedCategory} 
            onChange={handleCategoryChange} 
            className="mb-4 p-2 border rounded shadow-sm"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.title_en}
              </option>
            ))}
          </select>
        </div>

        <button 
          className="bg-secondColor h-[50px] text-white px-4 rounded mb-4 hover:bg-blue-600"
          onClick={() => navigate('/admin/addpackages')} // Navigate to Add Package form
        >
          Add New Package
        </button>
      </div>
      
      <Table
        data={filteredPackages.map(pkg => ({
          ...pkg,
          name: i18n.language === 'ar' ? pkg.name_ar : pkg.name_en, // Select name based on language
          category: i18n.language === 'ar' ? pkg.categoryId.title_ar : pkg.categoryId.title_en, // Select category title based on language
        }))}
        headers={packageHeaders}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default PackageManagement;
