import React, { useState, useEffect } from "react";
import {
  FaBars,
  FaTimes,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaHome,
  FaComments,
  FaClipboardList,
  FaConciergeBell,
  FaTag,
  FaMapMarkerAlt,
  FaUsers,
} from "react-icons/fa";
import logo from "../../images/logo/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showContactSection, setShowContactSection] = useState(true);
  
  const isArabic = i18n.language === "ar";
  const isActive = (path) => location.pathname === path;

  const navLinks = [
    { path: "/", label: "navbar.home", icon: <FaHome /> },
    { path: "/package", label: "navbar.packages", icon: <FaClipboardList /> },
    { path: "/services", label: "navbar.services", icon: <FaConciergeBell /> },
    { path: "/offer", label: "navbar.offers", icon: <FaTag /> },
    { path: "/location", label: "navbar.location", icon: <FaMapMarkerAlt /> },
    { path: "/aboutPage", label: "navbar.about", icon: <FaUsers /> },
    { path: "/contact", label: "navbar.contact", icon: <FaEnvelope /> },
    { path: "/opinion", label: "navbar.testimonials", icon: <FaComments /> },
  ];

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowContactSection(scrollY <= window.innerHeight * 0.1);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const visibleLinks = windowWidth >= 1114 ? navLinks : navLinks.slice(0, 4);
  const dropdownLinks = windowWidth < 1114 ? navLinks.slice(4) : [];

  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const changeLanguage = () => {
    const newLanguage = i18n.language === "ar" ? "eng" : "ar";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <nav
      className={`fixed top-0 z-50 left-0 w-full transition-all duration-300 ease-in-out font-arabic bg-thirdColor shadow-md`}
      dir={isArabic ? "rtl" : "ltr"}
    >
      {/* Contact Section */}
{showContactSection && (
  <div className="bg-secondColor py-2 sm:py-3 md:py-4 lg:py-5">
    <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center px-4">
      <div className="flex flex-wrap gap-2">
        <div className={`flex items-center gap-2 text-primeColor cursor-pointer ${isArabic ? "flex-row-reverse" : "flex-row"}`}>
          <FaPhoneAlt />
          <span
            className={`hidden md:inline text-white ${isArabic ? "text-right" : "text-left"}`}
            onClick={() => openExternalLink("tel:0501344319")}
          >
            0501344319
          </span>
        </div>
        <div
          className={`flex items-center gap-2 text-primeColor cursor-pointer ${isArabic ? "flex-row-reverse" : "flex-row"}`}
          onClick={() => openExternalLink("mailto:asmc.lab@gmail.com")}
        >
          <FaEnvelope />
          <span className="hidden md:inline text-white">asmc.lab@gmail.com</span>
        </div>
      </div>
      <div className="flex gap-4 text-2xl mt-2 md:mt-0">
        <button onClick={() => openExternalLink("https://www.facebook.com")} className="text-white hover:text-blue-800">
          <FaFacebook />
        </button>
        <button onClick={() => openExternalLink("https://api.whatsapp.com")} className="text-white hover:text-blue-900">
          <FaWhatsapp />
        </button>
        <button onClick={() => openExternalLink("https://www.instagram.com")} className="text-white hover:text-pink-500">
          <FaInstagram />
        </button>
      </div>
    </div>
  </div>
)}


      {/* Navbar */}
      <div className="w-full mx-auto px-4 py-2 flex items-center sm:px-6 h-[13vh] lg:px-8">
        <div className="flex justify-between items-center w-full h-16">
          {/* Logo and Menu */}
          <div className={`flex justify-between ${isArabic ? "mr-[50px]" : "ml-[50px]"} items-center gap-4 w-[90%]`}>
            <div className="flex-shrink-0 flex items-center w-full md:w-1/6">
              <Link to="/">
                <img
                  className="w-full max-w-[120px] md:max-w-[60%] mx-auto cursor-pointer"
                  src={logo}
                  alt="Logo"
                />
              </Link>
            </div>

            {/* Main Menu */}
            <div className="hidden md:flex items-center gap-4 w-[90%] mx-auto">
              {visibleLinks.map(({ path, label }, index) => (
                <Link
                  key={index}
                  to={path}
                  className={`hover:text-gray-800 px-3 py-2 rounded-md text-medium font-extrabold no-underline hover:bg-[#B1CF4B] border-[#B1CF4B] hover:bg-opacity-30 rounded-lg text-center break-words ${isActive(path) ? "text-primeColor border-b-4 border-[#B1CF4B]" : "text-white"}`}
                >
                  {t(label)}
                </Link>
              ))}

              {dropdownLinks.length > 0 && (
                <div className="relative">
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="px-3 py-2 rounded-md text-sm font-medium no-underline hover:bg-[#B1CF4B] hover:bg-opacity-20 rounded-lg text-center break-words text-white"
                  >
                    {t("navbar.more")}
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute z-10 right-0 mt-2 w-48 bg-secondColor shadow-lg rounded-md">
                      {dropdownLinks.map(({ path, label }, index) => (
                        <Link
                          key={index}
                          to={path}
                          className={`block px-4 py-2 text-white p-2 no-underline hover:bg-primeColor ${isActive(path) ? "text-[#B1CF4B] border-b-4 border-[#B1CF4B]" : ""}`}
                        >
                          {t(label)}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Change Language Button */}
          <div className="hidden md:flex items-center w-[10%]">
            <button
              className="text-primeColor bg-thirdColor px-4 py-2 rounded-md text-[12px] border font-bold hover:bg-primeColor hover:text-white transition duration-300 ease-in-out shadow-md"
              onClick={changeLanguage}
            >
              {i18n.language === "ar" ? "ENG" : "AR"}
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100"
            >
              {isOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden z-50">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navLinks.map(({ path, label }, index) => (
              <Link
                key={index}
                to={path}
                onClick={() => setIsOpen(false)}
                className={`block px-3 py-2 rounded-md text-base font-medium ${isActive(path) ? "text-[#B1CF4B] bg-gray-700" : "text-white hover:bg-gray-700"}`}
              >
                {t(label)}
              </Link>
            ))}
            <div className="flex flex-col">
              <button
                className="text-primeColor bg-thirdColor px-4 py-2 rounded-md text-[12px] border font-bold hover:bg-primeColor hover:text-white transition duration-300 ease-in-out shadow-md"
                onClick={changeLanguage}
              >
                {i18n.language === "ar" ? "ENG" : "AR"}
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
