import React from 'react';

// Function to get the styles based on the social media platform
const getStyles = (text, language) => {
  if (language === 'ar') {
    // Arabic styles
    switch (text) {
      case 'انستجرام':
        return { bgColor: 'bg-gradient-to-r from-pink-500 to-yellow-500', textColor: 'text-white' };
      case 'فيس بوك':
        return { bgColor: 'bg-blue-600', textColor: 'text-white' };
      case 'وتس آب':
        return { bgColor: 'bg-green-500', textColor: 'text-white' };
      case 'العنوان':
        return { bgColor: 'bg-secondColor', textColor: 'text-white' };
      case 'الهاتف':
      default:
        return { bgColor: 'bg-secondColor', textColor: 'text-white' };
    }
  } else {
    // English styles
    switch (text) {
      case 'Instagram':
        return { bgColor: 'bg-gradient-to-r from-pink-500 to-yellow-500', textColor: 'text-white' };
      case 'Facebook':
        return { bgColor: 'bg-blue-600', textColor: 'text-white' };
      case 'WhatsApp':
        return { bgColor: 'bg-green-500', textColor: 'text-white' };
      case 'Address':
        return { bgColor: 'bg-secondColor', textColor: 'text-white' };
      case 'phone':
      default:
        return { bgColor: 'bg-secondColor', textColor: 'text-white' };
    }
  }
};

const SocialSection = ({ icon, text, link, phoneNumber, location, isAddress, language }) => {
  // Define the `href` based on the presence of link, phoneNumber, or location
  let href = link;
  if (phoneNumber) href = `tel:${phoneNumber}`;
  if (location) href = link; // Use the link property for the location

  // Get the background and text colors based on the social media type and language
  const { bgColor, textColor } = getStyles(text, language);

  // Handle dynamic direction for RTL/LTR
  const isRTL = language === 'ar';

  return (
    <a
      href={href}
      target={phoneNumber || location ? '_self' : '_blank'}
      rel={phoneNumber || location ? undefined : 'noopener noreferrer'}
      className={`flex items-center justify-between no-underline ${bgColor} rounded-lg p-4 mb-4 w-full hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-md ${
        isAddress ? 'lg:col-span-2' : ''
      }`}
    >
      <div
        className={`flex items-center text-xl lg:text-2xl font-medium ${textColor} transition-all duration-300 transform hover:translate-x-1 ${isRTL ? 'flex-row-reverse' : 'flex-row'} w-full`}
      >
        {/* Display Icon - Adapt with direction */}
        <span className={`transition-transform transform hover:rotate-12 ${isRTL ? 'ml-2' : 'mr-2'}`}>
          {icon}
        </span>

        {/* Display dynamic content based on type */}
        {phoneNumber ? (
          <span className={`lg:text-xl hover:text-primeColor cursor-pointer transition-all duration-300 overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-${isRTL ? 'right' : 'left'}`}>
            {phoneNumber}
          </span>
        ) : location ? (
          <span className={`lg:text-xl hover:text-primeColor cursor-pointer transition-all duration-300 overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-${isRTL ? 'right' : 'left'}`}>
            {location}
          </span>
        ) : (
          <span className={`lg:text-xl hover:text-primeColor cursor-pointer transition-all duration-300 overflow-hidden text-ellipsis whitespace-nowrap flex-1 text-${isRTL ? 'right' : 'left'}`}>
            {text}
          </span>
        )}
      </div>
    </a>
  );
};



export default SocialSection;
