import React, { useState } from 'react';
import GeneralInfo from './GeneralInfo';
import WeeklyInfo from './WeeklyInfo';
import { useFetch } from '../../hooks/useFetch';
import { useTranslation } from 'react-i18next';
import SpinnerLoading from '../../component/Loading/SpinnerLoading';
import InformationLoading from '../../component/Loading/InformationLoading';
import API_ENDPOINTS from '../../api';

const MedicalInformation = () => { 
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState('general');
  const [activeWeek, setActiveWeek] = useState(1);

  const { data, loading, error } = useFetch(API_ENDPOINTS.INFOS.GET_ALL);
  console.log(data);
  
  if (loading) return <div className='h-[80vh]'><SpinnerLoading/></div>;
  if (error) return <div className='h-[80vh]'>{error.message}</div>;
  if (!data.infos || !Array.isArray(data.infos)) return <p>{t('medicalInformation.noData')}</p>;

  const generalInfo = data.infos.filter(info => info.category === 'general');
  const weeklyInfo = data.infos.filter(info => info.category === 'weekly' && info.week === activeWeek);

  return (
    <div className="min-h-screen bg-gray-50 p-6" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <header className="text-center py-8">
        <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t('medicalInformation.title')}
        </h1>
        <p className="text-lg text-gray-600">{t('medicalInformation.description')}</p>
      </header>

      {/* Tabs Container */}
      <div className="flex flex-col sm:flex-row justify-center mb-12">
        <div className="flex flex-wrap justify-center gap-4">
          <button
            className={`text-lg font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out shadow-md ${
              activeTab === 'general' 
                ? 'bg-secondColor text-white transform scale-105' 
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() => setActiveTab('general')}
          >
            {t('medicalInformation.generalTab')}
          </button>
          <button
            className={`text-lg font-semibold py-2 px-6 rounded-full transition duration-300 ease-in-out shadow-md ${
              activeTab === 'weekly' 
                ? 'bg-secondColor text-white transform scale-105' 
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
            onClick={() => setActiveTab('weekly')}
          >
            {t('medicalInformation.weeklyTab')}
          </button>
        </div>
      </div>

      {/* Render Tab Content with Loading Skeleton */}
      {loading ? (
        <div className="grid gap-8 grid-cols-1 md:grid-cols-1 lg:grid-cols-2 max-w-6xl mx-auto px-4 py-8">
          {Array.from({ length: 4 }).map((_, index) => (
            <InformationLoading key={index} />
          ))}
        </div>
      ) : (
        <>
          {activeTab === 'general' && <GeneralInfo generalInfo={generalInfo} />}
          {activeTab === 'weekly' && <WeeklyInfo weeklyInfo={weeklyInfo} activeWeek={activeWeek} setActiveWeek={setActiveWeek} />}
        </>
      )}
    </div>
  );
};

export default MedicalInformation;
