import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Admin = () => {
  const { i18n } = useTranslation();
  const location = useLocation(); // Get the current location

  const navItems = [
    { name: "Packages", path: "/admin/packages/" },
    { name: "Package Category", path: "/admin/package-category/" },
    { name: "Offer", path: "/admin/offer/" },
    { name: "Opinions", path: "/admin/opinions/" },
    { name: "Information", path: "/admin/information/" },
  ];

  // Check if the current language is Arabic
  const isArabic = i18n.language === "ar";

  // Function to check if a nav item is active
  const isActive = (path) => location.pathname.includes(path);

  return (
    <div className={`min-h-screen flex bg-gray-100`}>
      {/* Sidebar */}
      <aside
        className={`w-64 bg-gray-800 text-white p-6 shadow-lg h-[100vh] sticky top-0 self-start ${
          isArabic ? "right-0" : "left-0"
        }`}
        style={{ bottom: "20px" }} // Stops at the footer
      >
        <h2
          className={`text-3xl font-bold mb-8 text-center ${
            isArabic ? "text-right" : "text-left"
          }`}
        >
          {isArabic ? "لوحة الإدارة" : "Admin Panel"}
        </h2>
        <nav className="space-y-2">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`block px-4 py-2 rounded-lg transition-colors duration-300 flex items-center ${
                isActive(item.path)
                  ? "bg-gray-600 text-white"
                  : "hover:bg-gray-700 text-gray-300"
              }`}
              style={{ textDecoration: "none" }} // Remove underline
            >
              <span className={`flex-grow ${isArabic ? "text-right" : "text-left"}`}>
                {isArabic
                  ? item.name === "Package Category"
                    ? "نوع الباقة"
                    : item.name === "Packages"
                    ? "الباقة"
                    : item.name === "Offer"
                    ? "العروض"
                    : item.name === "Opinions"
                    ? "الآراء"
                    : "معلومات طبية"
                  : item.name}
              </span>
            </Link>
          ))}
        </nav>
      </aside>

      {/* Main Content */}
      <main
        className={`flex-1 p-8 bg-white rounded-lg shadow-lg ${
          isArabic ? "text-right" : "text-left"
        }`}
      >
        <Outlet /> {/* Nested routes will render here */}
      </main>
    </div>
  );
};

export default Admin;
