import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';

import Table from './Table'; // Reuse the Table component
import { useFetch } from '../../hooks/useFetch';
import i18n from '../../i18n';
import TableLoading from '../Loading/TableLoading';
import API_ENDPOINTS from '../../api';

const OfferManagement = () => { 
  const [offers, setOffers] = useState([]);
  const { data: offersData, loading, error: OffersError } = useFetch(API_ENDPOINTS.OFFERS.GET_ALL);
  const navigate=useNavigate()

  useEffect(() => {
    if (offersData && Array.isArray(offersData.sales)) {
      setOffers(offersData.sales);
    }
  }, [offersData]);

  const handleUpdate = (offerId) => {
    navigate(`/admin/addoffer/${offerId}`);
  };

  // Handle deleting an offer
  const handleDelete = async (id) => {
    try {
      await axios.delete(API_ENDPOINTS.OFFERS.DELETE(id));

      // Filter out the deleted offer without refetching
      setOffers(prevOffers => prevOffers.filter(offer => offer._id !== id));
    } catch (error) {
      console.error('Failed to delete offer:', error);
    }
  };
  
  


      // Specify the particular keys you want to display
  const specificKeys = ['name_ar','name_en', 'salePrice', 'salePercent',"offerDuration","tests"];

  // Filter headers that have valid values for the specific keys
  const offerHeaders = specificKeys.filter(key => 
    offers.some(offer => offer[key] !== null && offer[key] !== '' && offer[key] !== undefined)
  );
  if ( loading) return <div className="text-center"><TableLoading/></div>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Offer Management</h2>
      
      {/* Add offer button */}
      <Link to="/admin/addoffer">
        <button className="bg-secondColor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
          Add Offer
        </button>
      </Link>

      <Table
        data={offers.map(offer => ({
          ...offer,
          name: i18n.language === 'ar' ? offer.name_ar : offer.name_en}))}
        headers={offerHeaders}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default OfferManagement;
