import React from 'react';

const PackageLoading = () => {
  return (
    <div className="bg-white cardpackage shadow-lg rounded-[20px] overflow-hidden hover:shadow-2xl transform transition-transform duration-300 flex flex-col animate-pulse">
      {/* Image Section - Placeholder */}
      <div className="relative w-full h-0 pb-[70%] overflow-hidden bg-gray-300">
        {/* Empty block for image placeholder */}
      </div>

      {/* Package Info Section */}
      <div className="flex-grow p-4 flex flex-col justify-between">
        <div className="h-6 bg-gray-300 rounded mb-4"></div> {/* Placeholder for title */}
        <div className="h-4 bg-gray-300 rounded mb-6 w-2/3"></div> {/* Placeholder for text */}

        {/* Buttons - Placeholder */}
        <div className="flex justify-center gap-4">
          <div className="bg-gray-300 h-10 w-24 rounded"></div> {/* Button placeholder */}
          <div className="bg-gray-300 h-10 w-24 rounded"></div> {/* Button placeholder */}
        </div>
      </div>
    </div>
  );
};

export default PackageLoading;
