import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoArrowForward } from 'react-icons/io5'; 
import { Link } from 'react-router-dom';
import LocationImage from "../../images/Assets/locaTion2.jpg";
import { useTranslation } from 'react-i18next';

// Reusable Component for Address Item
const AddressItem = ({ country, governorate, city, externalLink }) => (
  <div className="flex flex-col md:flex-row items-center w-[80%] justify-between space-y-2 md:space-y-0 md:space-x-4 p-4 rounded-lg shadow-lg bg-backgroundColor2 border border-secondColor transition-transform duration-300">

    {/* Country Section */}
    <div className="flex items-center gap-[5px] text-secondColor rounded-lg">
      <FaMapMarkerAlt className="text-2xl" />
      <h2 className="text-xl font-semibold">{country}</h2>
    </div>

    {/* Governorate Section */}
    <div className="flex items-center">
      <h2 className="text-xl font-semibold text-secondColor">{governorate}</h2>
    </div>

    {/* City Section with Highlighted Focus */}
    <a
      href={externalLink}
      target="_blank" // Opens link in a new tab
      rel="noopener noreferrer" // Security best practice
      className="flex items-center animate-bounce space-x-2 text-white bg-secondColor hover:bg-secondColor transition duration-300 px-4 py-2 rounded-lg hover:scale-105 w-full md:w-auto"
    >
      <span className="text-2xl font-bold">{city}</span>
      <IoArrowForward className="text-4xl" />
    </a>
  </div>
);

// Reusable Component for Worker Time Schedule
const WorkerSchedule = ({ day, time }) => (
  <>
  {day&&time&&
    (
    <div className="flex flex-col md:flex-row justify-between w-[50%] mx-auto  rounded-lg shadow-md bg-backgroundColor2 items-center text-lg">
    {/* Day Section */}
    <span className="px-6 py-2 w-full md:w-[30%] bg-secondColor text-backgroundColor rounded-lg shadow-md font-bold text-center">
      {day}
    </span>

    {/* Time Section */}
    <span className="px-6 py-2 w-full md:w-[70%] text-green-700 rounded-lg text-center">
      {time}
    </span> 
  </div>
    )
  }
  <span className="px-6 py-2 w-full md:w-auto mx-2 bg-secondColor text-backgroundColor rounded-lg shadow-md font-bold text-center">
    {day}
  </span>
  </>
);

const Location = () => {
  const { t } = useTranslation(); // Use translation hook

  return (
    <div className="min-h-screen bg-backgroundColor p-4">
      {/* Header */}
      <header className="text-center py-4">
          <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
            {t('location.title')}
          </h1>
          <h2 className="text-xl font-medium text-secondColor">
            {t('location.description')}
          </h2>
      </header>

      {/* Location Image */}
      <div className="w-full h-[200px] mb-8">
        <img 
          src={LocationImage} 
          alt="Location" 
          className="w-full h-full object-cover rounded-lg shadow-lg" 
        />
      </div>

      {/* Location Address */}
      <section className="text-center mb-12">
        <h2 className="text-xl font-bold text-secondColor mb-4 after:block after:w-[150px] after:h-[1px] after:bg-secondColor after:mx-auto after:mt-2">
          {t('location.addressTitle')}
        </h2>
        <div className="flex items-center justify-center space-x-12">
          <AddressItem
            country={t('location.country')}
            governorate={t('location.governorate')}
            city={t('location.city')}
            externalLink={"https://www.google.com/maps/place/Alshifa+medical+laboratory/@25.3429584,56.3490257,17z/data=!3m1!4b1!4m6!3m5!1s0x3ef44f82e0f0798d:0xcbf6eba9f41c3fcb!8m2!3d25.3429584!4d56.3490257!16s%2Fg%2F11kj_8l5q_?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"}
          />
        </div>
      </section>

      {/* Separation Line */}
      <hr className="border-t-2 border-secondColor mb-12" />

      {/* Worker Time */}
      <div className="text-center mb-12 space-y-8">
        <h2 className="text-xl font-bold text-thirdColor mb-4 after:block after:w-[150px] after:h-[1px] after:bg-secondColor after:mx-auto after:mt-2">
          {t('location.scheduleTitle')}
        </h2>
        <div className="flex flex-col gap-6">
  {/* Group 1: Saturday, Sunday, Tuesday, Thursday (Same time) */}
  <div className="p-4 rounded-lg bg-gray-100 shadow-md">
    <div className="flex flex-wrap justify-center">
      <WorkerSchedule day={t('location.schedule.saturday')} />
      <WorkerSchedule day={t('location.schedule.sunday')} />
      <WorkerSchedule day={t('location.schedule.tuesday')} />
      <WorkerSchedule day={t('location.schedule.thursday')} />
    </div>
    {/* Shared Time for Group */}
    <div className="mt-2 text-green-800 text-center font-semibold text-xl">
      {t('location.schedule.tuesdayTime')}
    </div>
  </div>

  {/* Group 2: Monday, Wednesday (Same time) */}
  <div className="p-4 rounded-lg  bg-gray-100 shadow-md">
    <div className="flex flex-wrap  justify-center">
      <WorkerSchedule day={t('location.schedule.monday')} />
      <WorkerSchedule day={t('location.schedule.wednesday')} />
    </div>
    {/* Shared Time for Group */}
    <div className="mt-2 text-green-800 text-center font-semibold text-xl">
      {t('location.schedule.wednesdayTime')}
    </div>
  </div>

  {/* Friday (Unique time) */}
  <div className="p-4 rounded-lg bg-gray-100 shadow-md">
    <div className="flex justify-center">
      <WorkerSchedule day={t('location.schedule.friday')} />
    </div>
    {/* Unique Time for Friday */}
    <div className="mt-2 text-green-800 text-center font-semibold text-xl">
      {t('location.schedule.fridayTime')}
    </div>
  </div>
</div>


      </div>

      {/* Booking Button */}
      <div className="text-center">
        <Link to="/package" className="px-6 py-3 bg-secondColor text-backgroundColor font-bold text-lg rounded-lg transition duration-300 hover:text-white hover:opacity-[70%]">
          {t('location.viewPackages')}
        </Link>
      </div>
    </div>
  );
};

export default Location;
