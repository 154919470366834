import React from 'react';

const TableLoading = () => {
  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md animate-pulse">
      <h2 className="text-2xl font-bold mb-4 bg-gray-300 h-8 w-48 rounded-md"></h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-md">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-200">
                <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
              </th>
              <th className="px-6 py-3 bg-gray-200">
                <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
              </th>
              <th className="px-6 py-3 bg-gray-200">
                <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
              </th>
              <th className="px-6 py-3 bg-gray-200">
                <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5].map((row) => (
              <tr key={row} className="bg-white border-b">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="h-6 w-32 bg-gray-300 rounded-md"></div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableLoading;
