import React from 'react';

const TextArea = ({ label, value, onChange, placeholder, rows = 4, name }) => {
  return (
    <div className="mb-4 mx-auto w-[100%]">
      {label && <label className="block mb-1 text-lg font-semibold text-gray-700">{label}</label>}
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="w-[100%] p-3 rounded-lg border border-gray-300 focus:border-primeColor focus:ring-2 focus:ring-primeColor focus:outline-none transition-all duration-300 shadow-md resize-none"
      />
    </div>
  );
};

export default TextArea;
