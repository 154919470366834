import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../pages/Forms/InputField';
import TextArea from '../../pages/Forms/TextArea';
import SpinnerLoading from '../Loading/SpinnerLoading';
import ConfirmationPopup from '../../pages/Forms/Confirm';
import SendingStatusPopup from '../../pages/Forms/SendingStatusPopup';
import API_ENDPOINTS from '../../api';

const AddInfo = () => {
  const { infoId } = useParams(); // Get the infoId from the URL parameters
  const navigate = useNavigate();

  // State for medical information data
  const [medicalInfo, setMedicalInfo] = useState({
    title_en: '',
    title_ar: '',
    description_en: '',
    description_ar: '',
    category: 'general',
    week: '',
    month: ''
  });
  const [isEditing, setIsEditing] = useState(false); // Check if editing or adding new info
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // Confirmation popup
  const [sendingStatus, setSendingStatus] = useState(''); // Sending status for success or failure
  const [error, setError] = useState(''); // Handle errors
  const [loading, setLoading] = useState(false); // Loading state for fetching data

  // Fetch info details if in edit mode
  useEffect(() => {
    if (infoId) {
      setLoading(true); // Start loading before fetching data
      axios.get(API_ENDPOINTS.INFOS.GET_ONE(infoId))
        .then((response) => {
          const data = response.data.info;
          console.log(data);
          
          setMedicalInfo({
            title_en: data.title_en,
            title_ar: data.title_ar,
            description_en: data.description_en,
            description_ar: data.description_ar,
            category: data.category || 'general',
            week: data.week || '',
            month: data.month || ''
          });
          setIsEditing(true); // Set to editing mode
        })
        .catch((err) => {
          setError('Failed to load medical info details');
        })
        .finally(() => setLoading(false)); // Stop loading after the request finishes
    }
  }, [infoId]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMedicalInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setMedicalInfo({
      ...medicalInfo,
      category: value,
      week: value === 'weekly' ? medicalInfo.week : '', // Reset week if category is not weekly
      month: value === 'weekly' ? medicalInfo.month : '' // Reset month if category is not weekly
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmOpen(true); // Open confirmation popup
  };

  // Handle confirmation of form submission
  const handleConfirmSubmit = async () => {
    setIsConfirmOpen(false); // Close confirmation popup
    setSendingStatus('sending'); // Set status to 'sending'
 
    try {
      if (isEditing) {
        // If editing, send PATCH request to update medical info
        await axios.patch(API_ENDPOINTS.INFOS.UPDATE(infoId), medicalInfo);
      } else {
        // If adding new, send POST request to add new medical info
        await axios.post(API_ENDPOINTS.INFOS.ADD, medicalInfo);
      }
      setSendingStatus('success'); // Set status to 'success'
      //navigate('/admin/medical-info');
    } catch (err) {
      setSendingStatus('failure'); // Set status to 'failure' if there's an error
      console.error(err.response.data);
    }
  };

  // Handle canceling the form submission
  const handleCancelSubmit = () => {
    setIsConfirmOpen(false); // Close confirmation popup
  };

  // Handle closing the sending status popup
  const handleClosePopup = () => {
    if (sendingStatus === 'success') {
      navigate('/admin/information'); // Navigate to packages page after success
    }
    setSendingStatus(''); // Clear sending status
  };

  // Render loading spinner if data is still being fetched
  if (loading) return <SpinnerLoading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">
        {isEditing ? 'Edit Medical Info' : 'Add New Medical Info'}
      </h3>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Form fields */}
        <InputField
          label="Title (English)"
          type="text"
          name="title_en"
          value={medicalInfo.title_en}
          onChange={handleInputChange}
          required
        />
        <InputField
          label="Title (Arabic)"
          type="text"
          name="title_ar"
          value={medicalInfo.title_ar}
          onChange={handleInputChange}
          required
        />
        <TextArea
          label="Description (English)"
          name="description_en"
          value={medicalInfo.description_en}
          onChange={handleInputChange}
          required
        />
        <TextArea
          label="Description (Arabic)"
          name="description_ar"
          value={medicalInfo.description_ar}
          onChange={handleInputChange}
          required
        />
        
        {
          /* Category Select */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Category</label>
          <select
            name="category"
            value={medicalInfo.category}
            onChange={handleCategoryChange}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            required
          >
            <option value="general">General</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>

        {/* Week and Month Inputs (conditional) */}
        {medicalInfo.category === 'weekly' && (
          <>
            <InputField
              label="Week (1-4)"
              type="number"
              name="week"
              value={medicalInfo.week}
              onChange={handleInputChange}
              min="1"
              max="4"
              required
            />
            <div>
              <label className="block text-sm font-medium text-gray-700">Month</label>
              <select
                name="month"
                value={medicalInfo.month}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                required
              >
                <option value="">Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
          </>
        )}


        <button
          type="submit"
          className="bg-green-600 text-white p-2 rounded-md hover:bg-green-700"
        >
          {isEditing ? 'Update Information' : 'Submit Medical Information'}
        </button>
      </form>

      {/* Confirmation Popup */}
      {isConfirmOpen && (
        <ConfirmationPopup
          onConfirm={handleConfirmSubmit}
          onCancel={handleCancelSubmit}
        />
      )}

      {/* Sending Status Popup */}
      {sendingStatus && (
        <SendingStatusPopup status={sendingStatus} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default AddInfo;
