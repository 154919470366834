import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Table from './Table'; // Reuse the Table component
import { useFetch } from '../../hooks/useFetch';
import i18n from '../../i18n';
import TableLoading from '../Loading/TableLoading';
import API_ENDPOINTS from '../../api';

const InformationManagement = () => {  
  const [information, setInformation] = useState([]);
  const [category, setCategory] = useState('all');
  const navigate = useNavigate(); // Initialize useNavigate
  const { data: infoData, loading, error: infoError } = useFetch(API_ENDPOINTS.INFOS.GET_ALL);
    console.log(infoData);
    
  useEffect(() => {
    if (infoData && Array.isArray(infoData.infos)) {
      setInformation(infoData.infos);
    }
  }, [infoData]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value); 
  };

  const filteredInformation = information.filter(info => 
    category === 'all' ? true : info.category === category
  );

  const handleUpdate = (id) => {
    console.log('Update information:', id);
    // Navigate to the update form with the information ID
    navigate(`/admin/addinfo/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(API_ENDPOINTS.INFOS.DELETE(id));
      // Refresh information after deletion
      setInformation(information.filter(info => info._id !== id));
      console.log('Successfully deleted information:', id);
    } catch (error) {
      console.error('Failed to delete information:', error);
    }
  };

  const infoHeaders = ['title', 'category', 'week', 'month'];

  if (loading) return <div className="text-center"><TableLoading/></div>;
  if (infoError) return <div className="text-red-500">Error: {infoError.message}</div>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Information Management</h2>

      {/* Add Information Button */}
      <div className="mb-4 flex justify-center gap-[30px]">
        {/* Filter by Category */}
        <div>
          <label className="mr-2">Filter by Category:</label>
          <select 
            value={category} 
            onChange={handleCategoryChange} 
            className="border rounded py-2 px-3">
            <option value="all">All</option>
            <option value="general">General</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>

        {/* Add New Information Button */}
        <Link 
          to="/admin/addinfo"  
          className="bg-secondColor no-underline text-white px-4 py-2 rounded shadow-md hover:bg-[#145489] transition duration-300 ease-in-out"
        >
          Add Information
        </Link>
      </div>

      {/* Table with filtered information */}
      <Table
        data={filteredInformation.map(info => ({
          ...info,
          title: i18n.language === 'ar' ? info.title_ar : info.title_en,
          description: i18n.language === 'ar' ? info.description_ar : info.description_en,
        }))}
        headers={infoHeaders}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default InformationManagement;
