import React from 'react'

const AdminConfirm = ({ message, onConfirm }) => {
  return (
    <div className="p-4 bg-green-200 rounded-lg shadow-md text-green-800">
    <p>{message}</p>
    <button 
      onClick={onConfirm} 
      className="mt-2 px-4 py-2 bg-primeColor text-white rounded-lg shadow-md"
    >
      OK
    </button>
  </div>  )
}

export default AdminConfirm