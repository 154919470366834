import React, { useState } from 'react';
import InputField from './InputField';
import MyDatePicker from '../../component/Datepicker/MyDatePicker';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Import icons
import { ArrowUpward } from '@mui/icons-material';
import axios from 'axios'; // Import axios
import ConfirmationPopup from './Confirm';
import SendingStatusPopup from './SendingStatusPopup';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import API_ENDPOINTS from '../../api';
import i18n from '../../i18n';

const Reports = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    visitTime: '',
  });
  
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // State for confirmation popup
  const [sendingStatus, setSendingStatus] = useState(''); // State for sending status
  const [resetDate, setResetDate] = useState(false); // State for resetting the date

  // Format the date
  const formatAppointmentDateTime = (dateTime) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    const date = new Date(dateTime);

    const day = daysOfWeek[date.getDay()];
    const formattedDate = `${date.getDate()} / ${months[date.getMonth()]} / ${date.getFullYear()}`;
    return `${day}, ${formattedDate}`;
  };

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle date selection and format the date
  const handleDateSelect = (date) => {
    const formattedDate = formatAppointmentDateTime(date);
    setFormData((prev) => ({ ...prev, visitTime: formattedDate })); // Update form data with the formatted date
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsConfirmOpen(true); // Open confirmation popup
  };

  const handleConfirmSubmit = async () => {
    setIsConfirmOpen(false); // Close confirmation popup
    setSendingStatus('sending'); // Set sending status to 'sending'
    setResetDate(true); // Set to true to reset the date picker
    setTimeout(() => setResetDate(false), 0);
  
    const currentLang = i18n.language === 'ar' ? 'ar' : 'en'; // Check if the current language is Arabic, default to 'en'
  
    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      visitTime: formData.visitTime,
      lang: currentLang // Set the lang field
    };
    console.log("data", data.lang);
    
    try {
      const response = await axios.post(API_ENDPOINTS.MAILS.REPORT, data, {
        headers: {
          'Content-Type': 'application/json', // Use application/json for JSON data
        },
      });
      console.log('Form submitted successfully', response.data);
      setSendingStatus('success'); // Set sending status to 'success'
      setFormData({
        name: '',
        email: '',
        phone: '',
        visitTime: '',
      });
    } catch (error) {
      console.error('Error submitting form', error);
      setSendingStatus('failure'); // Set sending status to 'failure'
    }
  };

  const handleCancelSubmit = () => {
    setIsConfirmOpen(false); // Close confirmation popup without sending
  };

  const handleClosePopup = () => {
    setSendingStatus(''); // Reset sending status to close the popup
  };

  return (
    <div className="min-h-[90vh] bg-gray-50 py-8 relative">
      <header className="text-center">
        <h1 className="text-4xl font-bold text-primeColor mb-2">
          {t("reportRequest.title")}
        </h1>
        <p className="text-lg text-gray-700 mt-2">
          {t("reportRequest.instructions")}
        </p>
      </header>

      <form className="contactForm w-full max-w-[500px] mx-auto p-6 mt-8" onSubmit={handleSubmit}>
        {/* Input fields */}
        <InputField
          label={t("reportRequest.fullName")}
          type="text"
          placeholder={t("reportRequest.fullName")}
          value={formData.name}
          onChange={handleInputChange}
          name="name"
          required
        />
        <InputField
          label={t("reportRequest.email")}
          type="email"
          placeholder={t("reportRequest.email")}
          value={formData.email}
          onChange={handleInputChange}
          name="email"
          required
        />
        <InputField
          label={t("reportRequest.phone")}
          type="text"
          placeholder={t("reportRequest.phone")}
          value={formData.phone}
          onChange={handleInputChange}
          name="phone"
          required
        />

        {/* Date picker for visit time */}
        <div className="mb-4">
          <MyDatePicker holder={t("reportRequest.visitTime")} resetDate={resetDate} onChange={handleDateSelect} />
        </div>

        {/* Submit button */}
        <button
          type="submit"
          className="bg-secondColor text-white w-full py-3 rounded hover:bg-secondColor transition-colors duration-300"
        >
          {t("reportRequest.submit")}
        </button>
      </form>



      {/* Confirmation Popup */}
      {isConfirmOpen && (
        <ConfirmationPopup 
          onConfirm={handleConfirmSubmit} 
          onCancel={handleCancelSubmit} 
        />
      )}

      {/* Sending Status Popup */}
      {sendingStatus && (
        <SendingStatusPopup status={sendingStatus} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default Reports;
