import React from 'react';
import { FaFacebook, FaInstagram, FaMapMarkedAlt, FaWhatsapp, FaPoll } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import logo from '../../images/logo/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';


  const footerMenu = t('footer.sections', { returnObjects: true });
  const rightsText = t('footer.rights.text');
  const companyName1 = t('footer.rights.companyName1');
  const companyName2= t('footer.rights.companyName2');
  const companyName3= t('footer.rights.companyName3');
  const year = t('footer.rights.year');
  const hotline1 = '0501344319'; // Hotline number
  const hotline2 = '0569972730'; // Hotline number

  return (
    <footer
      className={`relative w-[100%] bottom-[0] bg-thirdColor text-white transition-all duration-500 ease-in-out py-8 font-arabic`}
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <div className="container mx-auto px-2 md:w-3/4">
        {/* Grid layout with a larger height for top-part */}
        <div className="grid grid-rows-4 gap-6">
          {/* Top part: Logo and Menu */}
          <div className="row-span-3">
            <div
              className={`top-part grid grid-cols-1 ${
                i18n.language === 'en' ? 'md:grid-cols-4' : 'md:grid-cols-3'
              } gap-8 items-start`}
            >
              {/* Logo section */}
              <div className="logo col-span-1 flex justify-center md:justify-start">
                <img src={logo} alt="Logo" className="h-auto w-[150px] hover:scale-110 transition-transform duration-500 ease-in-out" />
              </div>

              {/* Menu section: divided into columns */}
              <div
                className={`specs col-span-${i18n.language === 'en' ? '3' : '2'} grid grid-cols-1 md:grid-cols-3 gap-8`}
              >
                
{Array.isArray(footerMenu) && footerMenu.map((menu, index) => (
  <div key={index} className={`text-${isArabic ? 'right' : 'left'}`}>
    <h2 className="font-bold text-lg text-white mb-4 hover:text-[#376B95] transition-colors duration-300">
      {menu.header}
    </h2>
    <ul className="space-y-2">
      {menu.links.map((item, idx) => (
        <li key={idx} className="text-gray-400 hover:text-[#376B95] transition-colors duration-300">
          {/* Handle links like Developer Information and Survey */}
          {item.label === (isArabic ? "معلومات المطور" : "Developer Information") ? (
            <a href={`${item.url}`} className="no-underline text-primeColor">
              {item.label}
            </a>
          ) : item.label === (isArabic ? "الاستبيان" : "Survey") ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer" className="no-underline text-primeColor">
              {item.label}
            </a>
          ) : (
            <Link to={item.url} className="no-underline text-primeColor">
              {item.label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </div>
))}
              </div>
            </div>
          </div>

          <div className="w-full h-[1px] bg-backgroundColor rounded-full mx-auto"></div>

          {/* Bottom part: Hotline, Social icons and copyright */}
          <div className="row-span-1">
            <div className="bottom-part container mx-auto px-2 md:w-3/4">
              <div className={`flex flex-col justify-center items-center md:flex-row md:justify-between md:items-center md:space-y-0`}>
                {/* Hotline and Social Icons */}
                <div className="flex flex-col justify-center items-center md:items-start space-y-4">
                  {/* Hotline */}
                  <div className="hotline text-center md:text-left">
                     <p className="font-bold text-lg text-white cursor-pointer">
                            <span className="font-bold text-lg text-primeColor">Hotline: </span>
                            <a 
                              href={`tel:${hotline1}`} 
                              className="text-white no-underline cursor-pointer hover:text-primeColor transition-colors duration-300"
                            >
                              {hotline1}
                            </a>
                          </p>
                          <p className="font-bold text-lg text-white">
                            <span className="font-bold text-lg text-primeColor">Hotline: </span>
                            <a 
                              href={`tel:${hotline2}`} 
                              className="text-white no-underline cursor-pointer hover:text-primeColor transition-colors duration-300"
                            >
                              {hotline2}
                            </a>
                          </p>
                        </div>



                  {/* Social Icons with brand colors */}
                  <div className="flex justify-center gap-[15px]">
                    <a
                      href="https://www.instagram.com/alshifa.lab/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:scale-110 transition-transform duration-300"
                    >
                      <FaInstagram className="text-2xl" style={{ color: '#E1306C' }} />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100085742225186&mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:scale-110 transition-transform duration-300"
                    >
                      <FaFacebook className="text-2xl" style={{ color: '#4267B2' }} />
                    </a>
                    <a
                      href="https://api.whatsapp.com/send/?phone=971501344319&text&type=phone_number&app_absent=0"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:scale-110 transition-transform duration-300"
                    >
                      <FaWhatsapp className="text-2xl" style={{ color: '#25D366' }} />
                    </a>
                    <a
                      href="https://maps.app.goo.gl/7hsKaWyj28ZKFGpZ7"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:scale-110 transition-transform duration-300"
                    >
                      <FaMapMarkedAlt className="text-2xl" style={{ color: '#376B95' }} />
                    </a>
                    <a
                      href="https://surveyheart.com/form/5e89c1aef411e847dc5706ca"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:scale-110 transition-transform duration-300"
                    >
                      <FaPoll className="text-2xl" style={{ color: '#FF4500' }} />
                    </a>
                  </div>
                </div>

                {/* Rights and Company Name */}
                <div className="flex gap-[5px] items-center p-[6px] text-center md:text-left">
                  <p className="text-sm mb-[0] text-gray-300">{rightsText}</p>
                  <h6 className="font-bold text-secondColor text-lg mb-[0]">{companyName1}</h6>
                  <h6 className="font-bold text-primeColor text-lg mb-[0]">{companyName2}</h6>
                  <h6 className="font-bold text-secondColor text-lg mb-[0]">{companyName3}</h6>
                  <p className="text-sm mb-[0] text-gray-300">{year}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
