import React from 'react';
import { FaTrashAlt } from 'react-icons/fa'; // Import the trash icon

const FileUpload = ({ files, label, handleFileUpload, fileType, Icon, handleRemoveFile }) => {
  const isImage = (file) => {
    if (file?.type?.startsWith('image/')) {
      return true;
    }
    if (typeof file === 'string' && (file.startsWith('http://') || file.startsWith('https://'))) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex flex-col items-start w-full border p-4 rounded-lg shadow-md bg-white transition-transform duration-300 mb-4">
      <input
        type="file"
        id={fileType}
        accept="image/*"
        multiple
        onChange={(e) => handleFileUpload(e, fileType)}
        className="hidden"
      />
      <label
        htmlFor={fileType}
        className="flex items-center space-x-2 cursor-pointer border border-gray-300 rounded-lg p-2 transition-transform duration-300 hover:border-primeColor hover:scale-105"
      >
        {Icon}
        {label}
      </label>

      <div className="flex flex-wrap gap-2 mt-2">
        {files.map((file, index) => (
          <div key={index} className="relative">
            {isImage(file) && (
              <>
                <img
                  src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                  alt={label}
                  className="w-[50px] h-[50px] object-cover rounded border border-gray-300 shadow-md"
                />
                <button
                  type='button'
                  onClick={() => handleRemoveFile(fileType, index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <FaTrashAlt size={12} />
                </button>
              </>
            )}
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUpload;
