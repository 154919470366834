import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputField from '../../pages/Forms/InputField';
import FileUpload from './FileUpload';
import { FaFileUpload } from 'react-icons/fa';
import ConfirmationPopup from '../../pages/Forms/Confirm';
import SendingStatusPopup from '../../pages/Forms/SendingStatusPopup';
import API_ENDPOINTS from '../../api';

const AddOpinion = () => {
  const { opinionId } = useParams(); // Get the opinion ID from the URL
  const navigate = useNavigate();
  const [newOpinion, setNewOpinion] = useState({
    altText: '',
    imageOpinion: null,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // State for confirmation popup
  const [sendingStatus, setSendingStatus] = useState(''); // Track sending status
  const [error, setError] = useState('');

  // Fetch opinion data if editing
  useEffect(() => {
    if (opinionId) {
      console.log("Editing opinion with ID:", opinionId);
      setIsEditing(true);
      // Fetch the opinion data and populate the form
      const fetchOpinion = async () => {
        try {
          const { data } = await axios.get(API_ENDPOINTS.OPINIONS.GET_ONE(opinionId));
          setNewOpinion(data?.opinion);
        } catch (err) {
          setError('Failed to load opinion details');
        }
      };
      fetchOpinion();
    } else {
    }
  }, [opinionId]);

  const handleOpinionChange = (e) => {
    const { name, value } = e.target;
    setNewOpinion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle image upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewOpinion((prev) => ({ ...prev, imageOpinion: file }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmOpen(true); // Open confirmation popup
  };

  const handleConfirmSubmit = async () => {
    setIsConfirmOpen(false); // Close confirmation popup
    setSendingStatus('sending'); // Set status to 'sending'

    const formData = new FormData();
    formData.append('altText', newOpinion.altText);
    if (newOpinion.imageOpinion) {
      formData.append('imageOpinion', newOpinion.imageOpinion);
    }

    try {
      if (isEditing) {
        await axios.patch(API_ENDPOINTS.OPINIONS.UPDATE(opinionId), formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        await axios.post(API_ENDPOINTS.OPINIONS.ADD, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      setSendingStatus('success');
      navigate('/admin/opinions'); // Redirect to opinions page after success
    } catch (err) {
      console.error(err.response.data);
      setSendingStatus('failure');
    }
  };

  const handleCancelSubmit = () => {
    setIsConfirmOpen(false); // Close confirmation popup without submitting
  };

  const handleClosePopup = () => {
    setSendingStatus(''); // Reset sending status to close popup
  };

  return (
    <div className="p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">
        {isEditing ? 'Edit Opinion' : 'Add New Opinion'}
      </h3>

      <form onSubmit={handleSubmit} className="space-y-4">
        <InputField label="Alt Text" type="text" name="altText" value={newOpinion.altText} onChange={handleOpinionChange} required />

        <FileUpload
          file={newOpinion.imageOpinion}
          fileType="imageOpinion"
          label="Upload Opinion Image"
          handleFileUpload={handleFileUpload}
          Icon={<FaFileUpload className="text-primeColor w-5 h-5" />}
        />

        <button type="submit" className="px-4 py-2 font-bold text-white bg-primeColor rounded-lg hover:bg-green-700">
          {sendingStatus === 'sending' ? 'Loading....' : isEditing ? 'Update Opinion' : 'Add Opinion'}
        </button>
      </form>

      {/* Confirmation Popup */}
      {isConfirmOpen && (
        <ConfirmationPopup onConfirm={handleConfirmSubmit} onCancel={handleCancelSubmit} />
      )}

      {/* Sending Status Popup */}
      {sendingStatus && (
        <SendingStatusPopup status={sendingStatus} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default AddOpinion;
