import React from 'react';
import { services } from '../../Utilities/PackageData';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { t } from 'i18next';
import ServiceItem from '../Services/ServiceItem';
import i18n from '../../i18n';


const RandomService = () => { 
    
    const services = t("services.service", { returnObjects: true });
  const handleServiceClick = (id) => {
    console.log('Service clicked with ID:', id);
    // You can implement your logic here to navigate or show more details
  };
  // Limiting the displayed services to 2
  const limitedServices = services.slice(0, 2);

  return (
    <div className='bg-backgroundColor2 mt-[50px] bg-opacity-50 rounded-lg shadow-lg'>
    <header className="text-center py-6">
      <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2 transition-transform duration-300 hover:scale-105">
      {t("services.title")}
      </h1>
    </header>

    {/* Link aligned with the start of the services */}
    <div  className="mx-auto px-4 container animate-bounce mb-4 px-8 flex justify-start items-center relative">
      <Link
        to="/services" 
        className="flex items-center text-secondColor no-underline hover:text-primeColor transition duration-300 relative z-10"
      >
        <span>{t("services.title")}</span>{/* Animated arrow icon */}
        {i18n.language === "ar" ? <FaArrowLeft className="ml-2 " size={24} />:<FaArrowRight className="mr-2 " size={24} /> }
      </Link>
    </div>

    <div  className="container   mx-auto px-4 pb-8 grid grid-cols-1  sm:grid-cols-2 gap-6">
      {limitedServices.map((service) => (
        <ServiceItem key={service.id} service={service} handleService={handleServiceClick} />
      ))}
    </div>
  </div>
  );
}

export default RandomService;
