import { useEffect, useState } from "react";
import DatePicker from "tailwind-datepicker-react";
import { MdArrowBack, MdArrowForward, MdDateRange } from "react-icons/md"; // Import React Icons

const MyDatePicker = ({ holder, resetDate,name, onChange, width }) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const options = {
    title: holder,
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    clearBtnText: "مسح",
    maxDate: new Date("2050-01-01"),
    minDate: new Date("1950-01-01"),
    theme: {
      background: "bg-white",
      todayBtn: "text-white bg-primeColor",
      clearBtn: "bg-red-300",
      icons: "gray",
      text: "text-seocndColor hover:bg-white hover:text-secondColor",
      disabledText: "bg-gray-300 opacity-[50%]",
      input: "hidden",
      inputIcon: "hidden",
      selected: "bg-secondColor hover:text-white",
    },
    icons: {
      prev: () => <MdArrowForward className="text-secondColor" />,
      next: () => <MdArrowBack className="text-secondColor" />,
    },
    datepickerClassNames: "top-12 h-13 w-full",
    defaultDate: new Date(),
    language: "en",
    disabledDates: [],
    weekDays: ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date); // Call parent onChange if passed
    }
  };

  useEffect(() => {
    if (resetDate) {
      setSelectedDate(null); // Clear the selected date
    }
  }, [resetDate]);

  const handleClose = (state) => {
    setShow(state);
  };

  return (
    <div className={`col-span-3  relative  w-[100%] ${width?`md:w-[${width}%]` : ''}`}>
      {/* Label for the input */}
      {holder && <label className="block mb-1 text-lg font-semibold text-gray-700">{holder}</label>}

      {/* Input field to display the selected date */}
      <input
        type="text"
        name={name}
        readOnly
        value={selectedDate ? selectedDate.toLocaleDateString("en-GB", options.inputDateFormatProp) : ""}
        placeholder={holder}
        className="w-full p-3  rounded-lg border border-gray-300 focus:border-primeColor focus:ring-2 focus:ring-primeColor focus:outline-none transition-all duration-300 shadow-md cursor-pointer"
        onClick={() => setShow(true)} // Open date picker on input click
      />
      
      {/* DatePicker component */}
      <DatePicker
        options={options}
        onChange={handleDateChange}
        show={show}
        setShow={handleClose}
      />
    </div>
  );
};

export default MyDatePicker;
