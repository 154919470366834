import React from 'react'
import { FaSpinner } from 'react-icons/fa'

const AdminSpinnerLoading = ({message}) => {
  return (
<div className="flex items-center justify-center space-x-2">
      <FaSpinner className="animate-spin text-primeColor" />
      <span>{message || 'Sending...'}</span>
    </div>  )
}

export default AdminSpinnerLoading