// SlideContent.js
import React from "react";

const SlideContent = ({ slide, onClick ,i18n }) => (
    <div className="hidden md:flex flex-col col-span-2 items-start justify-center text-left text-white space-y-4 z-10">
      <h1 className="text-5xl md:text-6xl font-extrabold leading-tight mb-2 text-secondColor" dir="rtl">
        {slide.header}
      </h1>
      <p className={`text-lg md:text-xl font-light leading-relaxed text-thirdColor ${i18n.language === 'ar' ? "text-end":"text-start"} tracking-wide`}>
        {slide.content}
      </p>
      <button
        onClick={onClick}
        className="px-6 py-3 bg-primeColor text-white rounded-md shadow-md hover:bg-secondColor transition duration-300 text-lg font-semibold"
      >
        {slide.labelBtn}
      </button>
    </div>
  );

export default SlideContent;
