import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTag, FaClock, FaFlask, FaCalendarAlt } from "react-icons/fa";

const SalesPackageSection = ({ currentPackage, t, i18n }) => {
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [index, setIndex] = useState(0);
  const isArabic = i18n.language === 'ar';

  const navigate = useNavigate();
  const imageSale = Array.isArray(
    currentPackage && isArabic ? currentPackage.image_ar : currentPackage.image_en
  ) ? (currentPackage && isArabic ? currentPackage.image_ar : currentPackage.image_en) : [];
  
  const changeSlide = (direction) => {
    setTimeout(() => {
      setIndex((prevIndex) => (prevIndex + direction + imageSale.length) % imageSale.length);
    }, 200);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const slideTimer = setInterval(() => changeSlide(1), 4000);
    
    
    return () => {
      clearInterval(slideTimer);
    };
  }, [index]);

  useEffect(() => {
    if (currentPackage && currentPackage.endDate) {
      const endDate = new Date(currentPackage.endDate);
      const today = new Date();
      const timeDiff = endDate - today;

      if (timeDiff > 0) {
        const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24) + 1);
        setTimeLeft(`${daysLeft} ${t("days")}`);
      } else {
        setTimeLeft(t("expired"));
      }
    }
  }, [currentPackage, t]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center w-full md:col-span-1">
        <div className="relative p-6 bg-opacity-60 backdrop-blur-md shadow-lg rounded-lg overflow-hidden max-w-[400px] max-h-[500px] transition-transform transform hover:scale-105 duration-300">
          <h2 className="text-gray-300 text-3xl text-secondColor font-bold mb-4 animate-pulse">
            {t("home.landingPage.salesPackage.header")}
          </h2>
          <div className="absolute top-4 left-4 bg-gray-400 text-gray-300 text-xs font-bold rounded-full px-2 py-1 animate-pulse">
            Loading...
          </div>
          <FaTag className="absolute top-4 right-4 text-gray-400 animate-pulse" size={30} />
          <div className="w-full h-32 bg-gray-400 mb-4 rounded animate-pulse"></div>
          <div className="flex flex-col justify-between h-[150px] px-2 text-center">
            <h3 className="text-gray-300 text-2xl font-bold animate-pulse">Loading...</h3>
            <p className="text-gray-400 text-md text-start animate-pulse">Loading...</p>
            <div className="flex justify-between">
              <p className="text-yellow-400 text-lg md:text-xl font-semibold animate-pulse">
                Loading...
              </p>
            </div>
          </div>
          <div className="w-full h-12 flex items-center justify-center px-6 bg-gray-400 text-gray-300 rounded-md shadow-md hover:bg-gray-500 transition duration-300 transform hover:scale-105 animate-pulse">
            Loading...
          </div>
        </div>
      </div>
    );
  }

  if (!currentPackage) {
    return <div className="text-white">{t("home.landingPage.salesPackage.noOffer")}</div>;
  }

  const packageName = i18n.language === "ar" ? currentPackage.name_ar : currentPackage.name_en;
  const packageDescription =
    i18n.language === "ar" ? currentPackage.description_ar : currentPackage.description_en;
  const packageImage = i18n.language === "ar" ? currentPackage.image_ar : currentPackage.image_en;

  const offerDuration = currentPackage.offerDuration;
  const salePrice = currentPackage.salePrice;
  const salePercent = currentPackage.salePercent;
  const testCount = currentPackage.tests;

  return (
    <div className="flex flex-col items-center mt-[50px] justify-center w-full md:col-span-1">
      <div className="relative p-6 bg-opacity-60 backdrop-blur-md shadow-lg rounded-lg overflow-hidden max-w-[400px] min-w-[300px] h-[500px] transition-transform transform hover:scale-105 duration-300">
        <div className={`absolute z-50 top-2 ${i18n.dir() === "rtl" ? "right-2" : "left-2"} flex flex-col gap-1`}>
          {(
            <div className="flex items-center w-fit gap-1 bg-blue-200 text-blue-800 px-2 py-1 rounded-full">
              <FaClock size={14} />
              <span className="text-sm">
                {isHovered ? t("offer.offerDuration") : ""} {offerDuration}
              </span>
            </div>
          )}
          {(
            <div className="flex items-center w-fit gap-1 bg-green-200 text-green-800 px-2 py-1 rounded-full">
              <FaFlask size={14} />
              <span className="text-sm">
                {isHovered ? ` ${t("offer.test")}` : ":"} {testCount}
              </span>
            </div>
          )}
          {(
            <div className="flex items-center w-fit gap-1 bg-orange-200 text-orange-800 px-2 py-1 rounded-full">
              <FaCalendarAlt size={14} />
              <span className="text-sm">
                {isHovered ? `${t("offer.timeleft")}` : ""} {timeLeft}
              </span>
            </div>
          )}
        </div>

        <div className={`absolute top-4 z-50 ${i18n.dir() === "rtl" ? "left-4" : "right-4"} bg-red-500 w-fit text-white text-xs font-bold rounded-full px-2 py-1`}>
          {isHovered ? salePercent : ""}% Sale
        </div>

        <div className="relative w-full h-[50%] mb-4">
          <img
            src={imageSale[index]}
            alt={`Sale ${packageName}`}
            className="w-full h-full object-fill rounded transition-transform duration-300 hover:scale-110"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </div>

        <div className="flex flex-col justify-between h-[30%] mb-[10px] px-2 text-center">
          <h3 className="text-secondColor text-2xl font-bold">{packageName}</h3>
          <div className="flex justify-between mb-[5px]">
            <p className="text-red-400 text-lg md:text-xl font-bold px-2 py-2 rounded">
              {salePrice} {t("currency")}
            </p>
          </div>
        </div>

        <div className="w-full h-[8%] flex items-center justify-center px-6">
          <button
            onClick={() => navigate("/package/detail", { state: currentPackage })}
            className="w-full h-12 flex items-center justify-center px-6 bg-primeColor text-white rounded-md shadow-md hover:bg-secondColor transition duration-300 transform hover:scale-105"
          >
            {t("home.landingPage.salesPackage.packageButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesPackageSection;
