// src/component/Services/ServiceItem.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineInformationCircle } from 'react-icons/hi'; // Info icon
import { MdOutlineMedicalServices } from 'react-icons/md'; // Report icon
import { FaHome } from 'react-icons/fa'; // Home icon
import { BsBoxSeam } from 'react-icons/bs'; // Package icon
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const ServiceItem = ({ service }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = () => {
    if (service.path) {
      navigate(service.path);
    } else {
      console.error('No path defined for this service.');
    }
  };

  const getServiceIcon = (iconType) => {
    switch (iconType) {
      case 'info':
        return <HiOutlineInformationCircle className="text-secondColor text-6xl" />;
      case 'report':
        return <MdOutlineMedicalServices className="text-secondColor text-6xl" />;
      case 'home':
        return <FaHome className="text-secondColor text-6xl" />;
      case 'package':
        return <BsBoxSeam className="text-secondColor text-6xl" />;
      default:
        return <HiOutlineInformationCircle className="text-secondColor text-6xl" />;
    }
  };
  const textAlignment = i18n.language === 'ar' ? 'text-right' : 'text-left';

  return (
    <div
      onClick={handleNavigation}
      className="cursor-pointer flex bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-500 ease-in-out transform hover:scale-105 animate-fadeIn font-arabic"
    >
      

      <div className="w-1/2 pl-4 flex flex-col justify-center">
        <h4 className={`${textAlignment} font-bold text-secondColor text-lg mb-2 font-arabic`}>
          {service.name_en}
        </h4>

        <p className={`${textAlignment} text-gray-600 text-sm transition-colors duration-300 hover:text-secondColor`}>
          {service.description_en
            ? service.description_en.length > 150
              ? `${service.description_en.slice(0, 150)}...`
              : service.description_en
            : 'No description available for this service.'}
        </p>
      </div>

      <div className="w-1/2 pr-4 flex items-center justify-center rounded-lg overflow-hidden bg-gray-100 p-4">
        {getServiceIcon(service.icon)}
      </div>

     
    </div>
  );
};

export default ServiceItem;
