import React, { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import OpinionGallery from './OpinionGallery';
import { useTranslation } from 'react-i18next'; // Import i18n hook
import { useFetch } from '../../hooks/useFetch';
import SpinnerLoading from '../../component/Loading/SpinnerLoading';
import API_ENDPOINTS from '../../api';

const Opinion = () => {
  const { t } = useTranslation(); // Destructure the translation function
  const { data: OpinionData, loading: OpinionLoading, error: OpinionError } = useFetch(API_ENDPOINTS.OPINIONS.GET_ALL)
  const [opinion,setOpinion]=useState([])
  console.log(opinion);
  
  useEffect(() => {
    if (OpinionData) {
      setOpinion(OpinionData.opinion);
    }
}, [OpinionData]);

if (OpinionLoading) return <div className='h-[80vh]'><SpinnerLoading/></div>;
if (OpinionError) return <div className='h-[80vh]'>{OpinionError.message}</div>;

  const handleGoogleReviewClick = () => {
    window.open('https://g.co/kgs/zkb9eoh', '_blank');
  };

  return (
    <div>
      <header className="text-center py-6 bg-gray-50 rounded-lg shadow-md mb-6">
        <h1 className="text-5xl font-bold text-primeColor mb-3 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t('opinion.title')} {/* Use translation for title */}
        </h1>
        {/* Subheading */}
        <h2 className="text-xl font-medium text-gray-700 mt-2">
          {t('opinion.subheading')} {/* Use translation for subheading */}
        </h2>
        
        {/* Centered Google Reviews Button */}
        <div className="flex justify-center mt-4">
          <button
            onClick={handleGoogleReviewClick}
            className="flex items-center justify-center px-5 py-3 bg-secondColor text-white rounded-lg hover:opacity-[80%] transition duration-300 shadow-lg transform hover:scale-105"
          >
            <FaGoogle className="mr-2" size={24} />
            <span className="text-lg font-semibold">{t('opinion.googleReviewButton')}</span> {/* Use translation for button text */}
          </button>
        </div>
      </header>
      {/* Opinion gallery */}
      <OpinionGallery opinion={opinion} />
    </div>
  );
}

export default Opinion;
