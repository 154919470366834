import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SpinnerLoading = () => {
    const { t } = useTranslation(); // i18n hook for translations
    const [isLoading, setIsLoading] = useState(true);


    if (!isLoading) return null; // Return null when loading is complete

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-primeColor bg-opacity-50 z-20">
            {/* Spinner Container */}
            <div className="relative flex flex-col items-center">
                {/* Spinner Circle */}
                <div className="w-16 h-16 border-4 border-t-4 border-transparent border-t-secondColor rounded-full animate-spin"></div>

                {/* Loading Text */}
                <div className="absolute text-primeColor text-lg font-semibold mt-4 animate-pulse">
                    {t('loading')}
                </div>
            </div>
        </div>
    );
};

export default SpinnerLoading;
