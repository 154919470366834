import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InputField from '../../pages/Forms/InputField'; // Adjust the path as necessary
import SpinnerLoading from './AdminSpinnerLoading'; // Path to SpinnerLoading component
import ConfirmMessage from './AdminConfirm'; // Path to ConfirmMessage component
import Table from './Table'; // Import the Table component
import API_ENDPOINTS from '../../api';

const AdminPackageCategoryForm = () => {
  const [formData, setFormData] = useState({
    title_en: '',
    title_ar: '',
  }); 
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track if we are editing a category
  const [editCategoryId, setEditCategoryId] = useState(null); // Store the id of the category being edited

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.Category.GET_ALL);
        setCategories(response.data.categories);
      } catch (error) {
        setError('Failed to fetch categories.');
        console.log(error);
        
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(''); // Clear previous errors

    try {
      if (isEditing) {
        // Update category by ID
        const response = await axios.patch(API_ENDPOINTS.Category.UPDATE(editCategoryId), formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setSuccessMessage('Category updated successfully!');
          setIsEditing(false);
          setEditCategoryId(null);
        }
      } else {
        // Add a new category
        const response = await axios.post(API_ENDPOINTS.Category.ADD, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200 || response.status === 201) {
          setSuccessMessage('Category added successfully!');
        }
      }

      // Reset form and state
      setFormData({
        title_en: '',
        title_ar: '',
      });
      setError('');
      setConfirmation(true);

      // Refresh categories after adding/updating
      const updatedCategories = await axios.get(API_ENDPOINTS.Category.GET_ALL);
      setCategories(updatedCategories.data.categories);

    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        // Display a specific error message returned from the API
        setError(err.response.data.message);
      } else {
        // General fallback error message
        setError(isEditing ? 'Failed to update category. Please try again.' : 'Failed to add category. Please try again.');
      }
      setLoading(false);
      console.error(err);
    }
  };


  const handleEdit = async (categoryId) => {
    try { 
      const response = await axios.get(API_ENDPOINTS.Category.GET_BY_ID(categoryId));
      const category = response.data.data; 
      console.log(category);
      // Assuming the API returns the category data in the response
      setFormData({
        title_en: category.title_en,
        title_ar: category.title_ar,
      });
      setIsEditing(true);
      setEditCategoryId(categoryId);
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // This gives a smooth scrolling effect
      });
    } catch (error) {
      setError('Failed to fetch category details.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(API_ENDPOINTS.Category.DELETE(id));
      setCategories(categories.filter((category) => category._id !== id));
    } catch (error) {
      setError('Failed to delete category.');
    }
  };

  const handleConfirm = () => {
    setConfirmation(false);
    setLoading(false);

  };

  const headers = ['title_en', 'title_ar', 'actions']; // Add actions column for edit/delete
  
  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">{isEditing ? 'Edit Package Category' : 'Add Package Category'}</h2>

      {/* Loading Spinner */}
      {loading && <SpinnerLoading message="Sending..." />}

      {/* Success Message Confirmation */}
      {confirmation && <ConfirmMessage message={successMessage} onConfirm={handleConfirm} />}

      {/* Error Message */}
      {error && <div className="bg-red-200 text-red-800 p-2 rounded mb-4">{error}</div>}

      {/* Input Fields */}
      {!loading && !confirmation && (
        <>
          <InputField
            label="Category Title (English)"
            type="text"
            value={formData.title_en}
            onChange={handleChange}
            placeholder="Enter the category title in English"
            required
            name="title_en"
          />

          <InputField
            label="Category Title (Arabic)"
            type="text"
            value={formData.title_ar}
            onChange={handleChange}
            placeholder="أدخل عنوان الفئة باللغة العربية"
            required
            name="title_ar"
          />

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-secondColor text-white rounded-lg shadow-md transition-transform transform hover:scale-105"
            onClick={handleSubmit}
          >
            {isEditing ? 'Update Category' : 'Add Category'}
          </button>
        </>
      )}

      {/* Display Category Table */}
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-4">Available Categories</h3>
        <Table
          data={categories}
          headers={headers}
          handleDelete={handleDelete}
          handleUpdate={handleEdit} // Pass the handleEdit method to the Table
        />
      </div>
    </div>
  );
};

export default AdminPackageCategoryForm;
