import React, { useState } from 'react';
import ActivePic from './ActivePic';
import { galleryImages } from '../../Utilities/PackageData';

const OpinionGallery = ({opinion}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isActive, setIsActive] = useState(false); // State to track if the active view is shown
  console.log("opinion",opinion);
  console.log("galleryimag",galleryImages);
  
  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    setIsActive(true); // Show active image view
  };

  const handleBackClick = () => {
    setIsActive(false); // Go back to gallery view
  };

  return (
    <div className="p-8 bg-gray-100 rounded-xl shadow-lg">
      {isActive ? (
        <ActivePic 
          activeIndex={activeIndex} 
          handleBackClick={handleBackClick} 
          galleryImages={galleryImages}
          opinion={opinion}
          setActiveIndex={setActiveIndex} 
          handleClick={handleThumbnailClick}
        />
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {opinion.map((opinion, index) => (
            <div 
              key={index} 
              className="relative group cursor-pointer rounded-lg overflow-hidden shadow-md transition-transform duration-300 hover:shadow-lg"
              onClick={() => handleThumbnailClick(index)}
            >
              {/* Thumbnail image */}
              <img 
                className="h-48 w-full object-cover transform transition-transform duration-300 ease-in-out group-hover:scale-105 hover:brightness-[50%]" 
                src={opinion.image} 
                alt={opinion.altText} // Keep alt text for accessibility
                loading="lazy"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OpinionGallery;
