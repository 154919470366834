import React from 'react';
import { useTranslation } from 'react-i18next';
import './About.scss';

// Import Images
import storyImage from '../../images/About/story.jpeg';
import visionImage from '../../images/About/see.jpeg';
import missionImage from '../../images/About/message.jpeg';
import valuesImage from '../../images/About/value.jpeg';

// Import Partner Logos
import partner1 from "../../images/logo/شركة ابوظبي الوطنية للتأمين.png";
import partner2 from "../../images/logo/mednet.png";
import partner3 from "../../images/logo/Al-Madallah.png";
import partner4 from "../../images/logo/nas_1.png";
import partner5 from "../../images/logo/1685339923_8158f4811f7b5dd3d725.png";

// Partner logos array
const partnerLogos = [partner1, partner2, partner3, partner4, partner5];

// About data array
const aboutData = [
  {
    path: '/story',
    titleKey: 'about.story.title',
    textKey: 'about.story.text',
    image: storyImage,
    dir: 'rtl',
  },
  {
    path: '/vision',
    titleKey: 'about.vision.title',
    textKey: 'about.vision.text',
    image: visionImage,
    dir: 'ltr',
  },
  {
    path: '/mission',
    titleKey: 'about.mission.title',
    textKey: 'about.mission.text',
    image: missionImage,
    dir: 'rtl',
  },
  {
    path: '/values',
    titleKey: 'about.values.title',
    textKey: 'about.values.text',
    image: valuesImage,
    dir: 'ltr',
  }
];

const AboutPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="overflow-y-auto font-arabic">
      <header className="text-center py-4">
        <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t('about.title')}
        </h1>
        <h2 className="text-xl font-semibold text-gray-600 mb-4">
          {t('about.description')}
        </h2>
      </header>

      {/* Story Section */}
      <Section 
        title={t(aboutData[0].titleKey)}
        text={t(aboutData[0].textKey)}
        image={aboutData[0].image}
        dir={i18n.language === "ar" ?aboutData[0].dir:"ltr"}
      />

      {/* Mission and Vision Section */}
      <div className="grid grid-cols-1 min-h-[80vh] md:grid-cols-2 gap-4 p-8">
        <CardSection 
          title={t(aboutData[2].titleKey)}
          text={t(aboutData[2].textKey)}
          image={aboutData[2].image}
          isMission
        />
        <CardSection 
          title={t(aboutData[1].titleKey)}
          text={t(aboutData[1].textKey)}
          image={aboutData[1].image}
        />
      </div>

      {/* Values Section */}
      <Section 
        title={t(aboutData[3].titleKey)}
        text={t(aboutData[3].textKey)}
        image={aboutData[3].image}
        dir={i18n.language === "ar" ? aboutData[3].dir: "rtl"}
      />

      {/* Partner Logos Carousel */}
      <PartnerCarousel partnerLogos={partnerLogos} t={t} />
      </div>
  );
};

const Section = ({ title, text, image, dir }) => (
  <section className={`container min-h-[80vh] mx-auto px-4 py-10`} dir={dir}>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="flex flex-col justify-center">
        <h2 className="text-4xl font-bold text-secondColor mb-4">{title}</h2>
        <p className="text-lg text-gray-700 mb-4">{text}</p>
      </div>
      <ImageCard image={image} />
    </div>
  </section>
);

const ImageCard = ({ image }) => (
  <div className="relative top-[4px] right-[7px] w-full max-w-md mx-auto bg-primeColor rounded-lg shadow-lg">
    <div className="absolute z-6666 transform translate-x-1/4 -translate-y-1/4">
      <DecorativeDots />
    </div>
    <img src={image} alt="About Image" className="relative w-full h-full top-[15px] left-[15px] rounded-lg object-cover w-full h-64 z-20 relative" />
  </div>
);

const DecorativeDots = () => (
  <svg width="100" height="100" className="relative z-555 text-blue-800 fill-current">
    <circle cx="10" cy="10" r="2" />
    <circle cx="30" cy="10" r="2" />
    <circle cx="50" cy="10" r="2" />
    <circle cx="20" cy="30" r="2" />
    <circle cx="40" cy="30" r="2" />
    <circle cx="60" cy="50" r="2" />
    <circle cx="30" cy="50" r="2" />
    <circle cx="20" cy="40" r="2" />
    <circle cx="10" cy="70" r="2" />
    <circle cx="80" cy="70" r="2" />
    <circle cx="70" cy="70" r="2" />
  </svg>
);

const CardSection = ({ title, text, image, isMission }) => (
  <div
    className={`relative flex items-center justify-center bg-cover bg-center rounded-lg shadow-lg p-8 text-white`}
    style={{ backgroundImage: `url(${image})` }}
  >
    <div className={`bg-black bg-opacity-50 p-6 rounded-lg w-full text-center ${isMission ? '' : 'opacity-75'}`}>
      <h2 className="text-3xl font-bold mb-4">{title}</h2>
      <p className="text-lg">{text}</p>
    </div>
  </div>
);

const PartnerCarousel = ({ partnerLogos, t }) => (
  <div className="w-full relative py-6 bg-gray-100 border-t border-gray-300">
    <h2 className="w-full text-center text-large text-secondColor font-bold mb-4 font-arabic">
      {t('home.landingPage.partnerLogos.title')} {/* Use the t function here */}
    </h2>
    <div className="w-full flex justify-center overflow-hidden">
      <div className="w-full flex  justify-center animate-logoScroll gap-8">
        {partnerLogos.map((logo, i) => (
          <img
            key={i}
            src={logo}
            alt={`Partner ${i + 1}`}
            className="w-[10%] object-contain opacity-80 transition-opacity duration-300 transform hover:scale-105"
          />
        ))}
      </div>
    </div>
  </div>
);


export default AboutPage;
