import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import TextArea from '../../pages/Forms/TextArea';
import InputField from '../../pages/Forms/InputField';
import ConfirmationPopup from '../../pages/Forms/Confirm'; // Import Confirmation Popup
import SendingStatusPopup from '../../pages/Forms/SendingStatusPopup'; // Import Status Popup
import API_ENDPOINTS from '../../api';

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar'; // Detect Arabic language

  // State Management
  const [type, setType] = useState(t('contact.form.inquiry'));
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [status, setStatus] = useState('');

  // Handle Input Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
  };

  // Form Submission
  const handleSubmit = async () => {
    if (!name || !email || !phone || !message) {
      setError(t('contact.form.error'));
      return;
    }

    setError(null);
    setLoading(true);
    setStatus('sending');

    const formData = {
      name,
      email,
      phone,
      type,
      message,
      lang: i18n.language, // Add the current language to the form data
    };

    try {
      await axios.post(API_ENDPOINTS.MAILS.CONTACT, formData, {
        headers: { 'Content-Type': 'application/json' },
      });
      console.log('Form submitted successfully');

      // Reset form fields
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setType(t('contact.form.inquiry'));
      setStatus('success');
    } catch (err) {
      console.error('Error submitting form', err);
      setStatus('failure');
    } finally {
      setLoading(false);
    }
  };

  // Confirmation Popup Handlers
  const confirmSubmit = () => setShowConfirmation(true);
  const handleConfirm = () => {
    setShowConfirmation(false);
    handleSubmit();
  };
  const handleCancel = () => setShowConfirmation(false);
  
  // Close Status Popup
  const handleCloseStatusPopup = () => setStatus('');

  return (
    <>
      <form className="contactForm w-full p-6 rounded-lg" dir={isRTL ? 'rtl' : 'ltr'}>
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <div className="flex mb-6 space-x-2 justify-evenly">
          <button
            type="button"
            onClick={() => setType(t('contact.form.inquiry'))}
            className={`px-4 py-2 rounded-lg font-semibold ${type === t('contact.form.inquiry') ? 'bg-secondColor text-white shadow-md' : 'bg-gray-200 text-black'}`}
          >
            {t('contact.form.inquiry')}
          </button>
          <button
            type="button"
            onClick={() => setType(t('contact.form.suggestion'))}
            className={`px-4 py-2 rounded-lg font-semibold ${type === t('contact.form.suggestion') ? 'bg-secondColor text-white shadow-md' : 'bg-gray-200 text-black'}`}
          >
            {t('contact.form.suggestion')}
          </button>
        </div>

        <InputField
          label={t('contact.form.name')}
          type="text"
          name="name"
          placeholder={t('contact.form.name')}
          value={name}
          onChange={handleChange}
        />
        <InputField
          label={t('contact.form.email')}
          type="email"
          name="email"
          placeholder={t('contact.form.email')}
          value={email}
          onChange={handleChange}
        />
        <InputField
          label={t('contact.form.phone')}
          type="text"
          name="phone"
          placeholder={t('contact.form.phone')}
          value={phone}
          onChange={handleChange}
        />
        <TextArea
          label={type === t('contact.form.inquiry') ? t('contact.form.inquiry') : t('contact.form.suggestion')}
          rows="4"
          placeholder={type === t('contact.form.inquiry') ? t('contact.form.inquiry') : t('contact.form.suggestion')}
          value={message}
          name="message"
          onChange={handleChange}
        />

        <button
          type="button"
          className={`bg-secondColor text-white w-full p-3 rounded-lg hover:bg-blue-700 transition duration-300 ${loading && 'opacity-50 cursor-not-allowed'}`}
          onClick={confirmSubmit}
          disabled={loading}
        >
          {loading ? t('contact.form.loading') : t('contact.form.submit')}
        </button>
      </form>

      {/* Confirmation Popup */}
      {showConfirmation && <ConfirmationPopup onConfirm={handleConfirm} onCancel={handleCancel} />}

      {/* Sending Status Popup */}
      {status && <SendingStatusPopup status={status} onClose={handleCloseStatusPopup} />}
    </>
  );
};

export default ContactForm;
