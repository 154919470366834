import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputField from '../../pages/Forms/InputField';
import FileUpload from './FileUpload';
import { FaFileUpload } from 'react-icons/fa';
import MyDatePicker from '../Datepicker/MyDatePicker';
import TextArea from '../../pages/Forms/TextArea';
import ConfirmationPopup from '../../pages/Forms/Confirm';
import SendingStatusPopup from '../../pages/Forms/SendingStatusPopup';
import API_ENDPOINTS from '../../api';

const AddOffer = () => {
  const { offerId } = useParams(); // Get the offer ID from the URL
  const navigate = useNavigate();
  const [newOffer, setNewOffer] = useState({
    name_en: '',
    name_ar: '',
    salePrice: '',
    salePercent: '',
    startDate: '',
    endDate: '',
    description_en: '',
    description_ar: '',
    tests: '',
    image_en: [], // Array for English images
    image_ar: [], // Array for Arabic images
  });
  
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); // State for confirmation popup
  const [sendingStatus, setSendingStatus] = useState(''); // Track sending status
  const [error, setError] = useState('');

  // Fetch offer data if editing
  useEffect(() => {
    if (offerId) {
      setIsEditing(true);
      // Fetch the offer data and populate the form
      const fetchOffer = async () => {
        try {
          const { data } = await axios.get(API_ENDPOINTS.OFFERS.GET_ONE(offerId));
          setNewOffer(data?.sale);
        } catch (err) {
          setError('Failed to load offer details');
        }
      };
      fetchOffer();
    }
    console.log(newOffer);
    console.log(isConfirmOpen);
    
    
  }, [offerId]);

  const handleOfferChange = (e) => {
    const { name, value } = e.target;
    setNewOffer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };



  const handleStartDateSelect = (date) => {
    setNewOffer((prev) => ({ ...prev, startDate: date }));
  };

  const handleEndDateSelect = (date) => {
    setNewOffer((prev) => ({ ...prev, endDate: date }));
  };

  const handleFileUpload = (e, fileType) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setNewOffer((prev) => ({
        ...prev,
        [fileType]: [...prev[fileType], ...files], // Append new files to existing array
      }));
    }
  };

  const handleRemoveFile = (fileType, index) => {
    setNewOffer((prev) => ({
      ...prev,
      [fileType]: prev[fileType].filter((_, i) => i !== index), // Remove file at specified index
    }));
  };


  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmOpen(true); // Open confirmation popup
  };

  const handleConfirmSubmit = async () => {
    setIsConfirmOpen(false); // Close confirmation popup
    setSendingStatus('sending'); // Set status to 'sending'

    const formData = new FormData();
    formData.append('name_en', newOffer.name_en);
    formData.append('name_ar', newOffer.name_ar);
    formData.append('salePrice', newOffer.salePrice);
    formData.append('salePercent', newOffer.salePercent);
    formData.append('startDate', newOffer.startDate);
    formData.append('endDate', newOffer.endDate);
    formData.append('description_en', newOffer.description_en);
    formData.append('description_ar', newOffer.description_ar);
    formData.append('tests', newOffer.tests);

   
    // Append all English images
  newOffer.image_en.forEach((file) => {
    formData.append('image_en', file);
  });

  // Append all Arabic images
  newOffer.image_ar.forEach((file) => {
    formData.append('image_ar', file);
  });
  for (let [key , value] of formData.entries()){
        console.log(key, value);

  }
    try {
      if (isEditing) {
        await axios.patch(API_ENDPOINTS.OFFERS.UPDATE(offerId), formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log('Offer updated successfully!');
      } else {
        await axios.post(API_ENDPOINTS.OFFERS.ADD, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log('Offer added successfully!');
      }
      setSendingStatus('success');
      navigate('/admin/offer'); // Redirect to offers page after success
    } catch (err) {
      console.error(err.response.data);
      setSendingStatus('failure');
    }
  };

  const handleCancelSubmit = () => {
    setIsConfirmOpen(false); // Close confirmation popup without submitting
  };

  const handleClosePopup = () => {
    setSendingStatus(''); // Reset sending status to close popup
  };

  return (
    <div className="p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">
        {isEditing ? 'Edit Offer' : 'Add New Offer'}
      </h3>

      <form onSubmit={handleSubmit} className="space-y-4">
        <InputField label="Offer Name (English)" type="text" name="name_en" value={newOffer.name_en} onChange={handleOfferChange} required />
        <InputField label="Offer Name (Arabic)" type="text" name="name_ar" value={newOffer.name_ar} onChange={handleOfferChange} required />
        <InputField label="Sale Price" type="number" name="salePrice" value={newOffer.salePrice} onChange={handleOfferChange} />
        <InputField label="Sale Percent (%)" type="number" name="salePercent" value={newOffer.salePercent} onChange={handleOfferChange} />

        <MyDatePicker holder="Sale Start Date" selectedDate={newOffer.startDate} onChange={handleStartDateSelect} />
        <MyDatePicker holder="Sale End Date" selectedDate={newOffer.endDate} onChange={handleEndDateSelect} />

        <TextArea label="Description (English)" name="description_en" value={newOffer.description_en} onChange={handleOfferChange} required />
        <TextArea label="Description (Arabic)" name="description_ar" value={newOffer.description_ar} onChange={handleOfferChange} required />

        <InputField label="Number of Tests" type="number" name="tests" value={newOffer.tests} onChange={handleOfferChange} required />

        {/* Separate file uploads for English and Arabic images */}
        <FileUpload files={newOffer.image_en} fileType="image_en" label="Upload Offer Image (English)" handleFileUpload={(e) => handleFileUpload(e, 'image_en')}  handleRemoveFile={handleRemoveFile}  Icon={<FaFileUpload className="text-primeColor w-5 h-5" />} />
        <FileUpload files={newOffer.image_ar} fileType="image_ar" label="Upload Offer Image (Arabic)" handleFileUpload={(e) => handleFileUpload(e, 'image_ar')} handleRemoveFile={handleRemoveFile} Icon={<FaFileUpload className="text-primeColor w-5 h-5" />} />

        <button type="submit" className="px-4 py-2 font-bold text-white bg-primeColor rounded-lg hover:bg-green-700">
          {sendingStatus === 'sending' ? 'Loading....' : isEditing ? 'Update Offer' : 'Add Offer'}
        </button>
      </form>

      {/* Confirmation Popup */}
      {isConfirmOpen && (
        <ConfirmationPopup onConfirm={handleConfirmSubmit} onCancel={handleCancelSubmit} />
      )}

      {/* Sending Status Popup */}
      {sendingStatus && (
        <SendingStatusPopup status={sendingStatus} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default AddOffer;
