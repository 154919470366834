const opinionImage1 =require('../images/Assets/opinion2.jpeg')
const opinionImage2 =require('../images/Assets/opinion.jpeg')

export const packages = [
  {
    "title": "باقات فحص العمالة المساعدة",
    "slug": "labor-packages",
    "packages": [
      {
        "name": "باقة العمالة المساعدة الأساسية",
        "slug": "basic-labor-package",
        "tests": 5,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة العمالة المساعدة الأساسية بلس",
        "slug": "basic-plus-labor-package",
        "tests": 6,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة العمالة المساعدة الشاملة",
        "slug": "comprehensive-labor-package",
        "tests": 12,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات الحساسية",
    "slug": "allergy-packages",
    "packages": [
      {
        "name": "باقة الحساسية الغذائية",
        "slug": "food-allergy-package",
        "tests": 20,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الحساسية الاستنشاقية",
        "slug": "inhalation-allergy-package",
        "tests": 20,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "اختبار عدم تحمل الطعام",
        "slug": "food-intolerance-package",
        "tests": 220,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات دلائل الأورام",
    "slug": "tumor-packages",
    "packages": [
      {
        "name": "فحص دلالات الأورام للسيدات",
        "slug": "women-tumor-package",
        "tests": 6,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "فحص دلالات الأورام للرجال",
        "slug": "men-tumor-package",
        "tests": 5,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات الأنيميا",
    "slug": "anemia-packages",
    "packages": [
      {
        "name": "باقة الأنيميا الأساسية",
        "slug": "basic-anemia-package",
        "tests": 4,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الأنيميا الشاملة",
        "slug": "comprehensive-anemia-package",
        "tests": 8,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات الفحوصات المتخصصة",
    "slug": "specialized-tests",
    "packages": [
      {
        "name": "باقة فحص ما قبل الزواج",
        "slug": "pre-marriage-package",
        "tests": 7,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة فحص الروماتيزم",
        "slug": "rheumatism-package",
        "tests": 7,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة مرض السكري",
        "slug": "diabetes-package",
        "tests": 16,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة متابعة قص المعدة",
        "slug": "post-bariatric-surgery-package",
        "tests": 16,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة فحص المخدرات في البول",
        "slug": "drug-screening-package",
        "tests": 10,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات الفحص الشامل",
    "slug": "comprehensive-packages",
    "packages": [
      {
        "name": "باقة الفحص الشامل الأساسية",
        "slug": "basic-comprehensive-package",
        "tests": 24,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الفحص الشامل الأساسية بلس",
        "slug": "basic-plus-comprehensive-package",
        "tests": 25,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الفحص الشامل",
        "slug": "comprehensive-package",
        "tests": 20,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الفحص الشامل بلس (EXTENDED)",
        "slug": "extended-comprehensive-package",
        "tests": 40,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة صحة الأطفال",
        "slug": "children-health-package",
        "tests": 14,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات ما بعد الإصابة بكوفيد 19",
    "slug": "post-covid-packages",
    "packages": [
      {
        "name": "باقة ما بعد كوفيد 19 الأساسية",
        "slug": "basic-post-covid-package",
        "tests": 17,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة ما بعد كوفيد 19 بلس",
        "slug": "post-covid-plus-package",
        "tests": 19,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات الوظائف الحيوية",
    "slug": "vital-function-packages",
    "packages": [
      {
        "name": "باقة وظائف الكبد",
        "slug": "liver-function-package",
        "tests": 10,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة وظائف الكلى",
        "slug": "kidney-function-package",
        "tests": 3,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة وظائف الغدة الدرقية",
        "slug": "thyroid-function-package",
        "tests": 3,
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الدهون",
        "slug": "lipid-profile-package",
        "tests": 5,
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  },
  {
    "title": "باقات أخري",
    "slug": "other-packages",
    "packages": [
      {
        "name": "باقة متابعة القولون",
        "slug": "colon-follow-up-package",
        "tests": [
          "تحليل الدم الخفي",
          "فحص البراز العام",
          "الكالبروتكتين",
          "فحص جرثومة المعدة"
        ],
        "image": require("../images/package/الحيوي.jpeg")
      },
      {
        "name": "باقة الفيتامينات والمعادن",
        "slug": "vitamin-mineral-package",
        "tests": [
          "صورة الدم الكاملة (CBC)",
          "مخزون الحديد (FERRITIN)",
          "الحديد (IRON)",
          "الكلوريد (CHLORIDE)",
          "الصوديوم (SODIUM)",
          "الفوسفور (PHOSPHORUS)",
          "الزنك (ZINC)",
          "فيتامين د (VIT D)",
          "فيتامين ب 12 (VIT B12)",
          "المغنيسيوم (MAGNESIUM)",
          "البوتاسيوم (POTASSIUM)",
          "الكالسيوم (CALCIUM)"
        ],
        "image": require("../images/package/الحيوي.jpeg")
      }
    ]
  }
];

export const packagesOffer = [
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة العمالة المساعدة الأساسية",
    originalPrice: 100,
    salePrice: 80,
    salePercent: 20,
    offerDuration: "5 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة العمالة المساعدة الأساسية بلس",
    originalPrice: 120,
    salePrice: 100,
    salePercent: 17,
    offerDuration: "3 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة الحساسية الغذائية",
    originalPrice: 200,
    salePrice: 150,
    salePercent: 25,
    offerDuration: "7 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة فحص دلالات الأورام للسيدات",
    originalPrice: 180,
    salePrice: 140,
    salePercent: 22,
    offerDuration: "4 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة فحص دلالات الأورام للرجال",
    originalPrice: 160,
    salePrice: 130,
    salePercent: 19,
    offerDuration: "5 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة الفحص الشامل الأساسية",
    originalPrice: 300,
    salePrice: 250,
    salePercent: 17,
    offerDuration: "10 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة ما بعد كوفيد 19 الأساسية",
    originalPrice: 220,
    salePrice: 180,
    salePercent: 18,
    offerDuration: "6 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة متابعة القولون",
    originalPrice: 250,
    salePrice: 200,
    salePercent: 20,
    offerDuration: "8 days"
  },
  {
    image: require("../images/package/الحيوي.jpeg"),
    title: "باقة الفيتامينات والمعادن",
    originalPrice: 150,
    salePrice: 120,
    salePercent: 20,
    offerDuration: "4 days"
  }
];

  
export const services = [
  {
    id: 1,
    name_en: 'معلومات طبية',
    description_en: 'احصل على معلومات طبية دقيقة وحديثة عن الحالات الطبية المختلفة والعلاجات المتاحة.',
    icon: 'info', // Icon type for Medical Information
    path: "/information"
  },
  {
    id: 2,
    name_en: 'طلب تقارير مخبارية',
    description_en: 'تقديم طلب للحصول على تقارير مخبرية دقيقة وشاملة لجميع الفحوصات الطبية.',
    icon: 'report', // Icon type for Laboratory Reports
    path: "/reports"
  },
  {
    id: 3,
    name_en: 'السحب المنزلي',
    description_en: 'خدمة السحب المنزلي تسمح لك بإجراء التحاليل في المنزل بكل راحة وسهولة.',
    icon: 'home', // Icon type for Home Sampling
    path: "/withdraw"
  },
  {
    id: 4,
    name_en: 'الباقات',
    description_en: 'اكتشف الباقات الصحية المختلفة التي تغطي جميع احتياجات الفحوصات والعلاج بأسعار مميزة.',
    icon: 'package', // Icon type for Packages
    path: "/package"
  }
];


export const medicalInfo = [
  { id: 1, title: "معلومات طبية عامة", description: "الحفاظ على نظام غذائي متوازن يساهم في تحسين الصحة العامة ويقلل من خطر الإصابة بالأمراض المزمنة.", category: "general" },
  { id: 2, title: "معلومة الأسبوع الأول", description: "المشي لمدة 30 دقيقة يوميًا يساعد في تحسين الدورة الدموية وصحة القلب.", category: "weekly", week: 1, month: "January" },
  { id: 3, title: "معلومة الأسبوع الثاني", description: "التقليل من تناول السكريات يقلل من خطر الإصابة بالسكري ويعزز الصحة العامة.", category: "weekly", week: 2, month: "January" },
  { id: 4, title: "معلومة الأسبوع الثالث", description: "شرب 8 أكواب من الماء يوميًا يساعد في الحفاظ على ترطيب الجسم.", category: "weekly", week: 3, month: "January" },
  { id: 5, title: "معلومة الأسبوع الرابع", description: "تناول الخضراوات الورقية مثل السبانخ والبروكلي يزود الجسم بالعناصر الغذائية الأساسية.", category: "weekly", week: 4, month: "January" },
  { id: 6, title: "معلومات طبية عامة", description: "النوم الكافي لمدة 7-8 ساعات ليلاً يحسن من وظائف الدماغ والجهاز المناعي.", category: "general" },
  { id: 7, title: "معلومة الأسبوع الأول", description: "الحفاظ على الوزن المثالي يساعد في الوقاية من العديد من الأمراض المزمنة.", category: "weekly", week: 1, month: "February" },
  { id: 8, title: "معلومة الأسبوع الثاني", description: "تناول الأطعمة الغنية بالألياف يحسن عملية الهضم ويقلل من خطر الإصابة بالإمساك.", category: "weekly", week: 2, month: "February" },
  { id: 9, title: "معلومة الأسبوع الثالث", description: "تقليل استهلاك الملح يساهم في خفض ضغط الدم ويحسن صحة القلب.", category: "weekly", week: 3, month: "February" },
  { id: 10, title: "معلومة الأسبوع الرابع", description: "ممارسة التأمل والتقنيات التنفسية تساهم في تقليل مستويات التوتر.", category: "weekly", week: 4, month: "February" },
  { id: 11, title: "معلومات طبية عامة", description: "التمرين المنتظم يعزز القوة العضلية ويقلل من مخاطر الأمراض القلبية.", category: "general" },
  { id: 12, title: "معلومة الأسبوع الأول", description: "التعرض لأشعة الشمس في الصباح يساعد الجسم في إنتاج فيتامين د.", category: "weekly", week: 1, month: "March" },
  { id: 13, title: "معلومة الأسبوع الثاني", description: "التقليل من الكافيين يساهم في تحسين جودة النوم والراحة.", category: "weekly", week: 2, month: "March" },
  { id: 14, title: "معلومة الأسبوع الثالث", description: "الحصول على فحوصات طبية دورية يساعد في الكشف المبكر عن الأمراض.", category: "weekly", week: 3, month: "March" },
  { id: 15, title: "معلومة الأسبوع الرابع", description: "تناول المكسرات مثل اللوز والجوز يوفر الدهون الصحية والألياف الغذائية.", category: "weekly", week: 4, month: "March" },
  { id: 16, title: "معلومات طبية عامة", description: "اليوغا والتأمل يساهمان في تحسين الصحة النفسية والبدنية.", category: "general" },
  { id: 17, title: "معلومة الأسبوع الأول", description: "النشاط البدني اليومي يقلل من خطر السمنة ويحسن الصحة العامة.", category: "weekly", week: 1, month: "April" },
  { id: 18, title: "معلومة الأسبوع الثاني", description: "تناول الفواكه بانتظام يزود الجسم بالفيتامينات والمعادن الضرورية.", category: "weekly", week: 2, month: "April" },
  { id: 19, title: "معلومة الأسبوع الثالث", description: "النوم الجيد يساعد في تحسين المزاج وزيادة التركيز.", category: "weekly", week: 3, month: "April" },
  { id: 20, title: "معلومة الأسبوع الرابع", description: "شرب العصائر الطبيعية يمنح الجسم الترطيب ويعزز الطاقة.", category: "weekly", week: 4, month: "April" }
];


export const galleryImages = [
  {
    src: opinionImage2,
    title: {
      en: 'Title for Image 1',
      ar: 'عنوان الصورة 1'
    },
    description: {
      en: 'This is a brief description of image 1.',
      ar: 'هذه وصف موجز للصورة 1.'
    }
  },
  {
    src: opinionImage1,
    title: {
      en: 'Title for Image 2',
      ar: 'عنوان الصورة 2'
    },
    description: {
      en: 'This is a brief description of image 2.',
      ar: 'هذه وصف موجز للصورة 2.'
    }
  },
  {
    src: opinionImage2,
    title: {
      en: 'Title for Image 3',
      ar: 'عنوان الصورة 3'
    },
    description: {
      en: 'This is a brief description of image 3.',
      ar: 'هذه وصف موجز للصورة 3.'
    }
  },
  {
    src: opinionImage1,
    title: {
      en: 'Title for Image 4',
      ar: 'عنوان الصورة 4'
    },
    description: {
      en: 'This is a brief description of image 4.',
      ar: 'هذه وصف موجز للصورة 4.'
    }
  },
  {
    src: opinionImage2,
    title: {
      en: 'Title for Image 5',
      ar: 'عنوان الصورة 5'
    },
    description: {
      en: 'This is a brief description of image 5.',
      ar: 'هذه وصف موجز للصورة 5.'
    }
  },
  {
    src: opinionImage1,
    title: {
      en: 'Title for Image 6',
      ar: 'عنوان الصورة 6'
    },
    description: {
      en: 'This is a brief description of image 6.',
      ar: 'هذه وصف موجز للصورة 6.'
    }
  },
  // Add more images here
];








      // Define the packages
 export  const comprehensivePackageData = [
    { title: 'فحص شامل 1', description: 'وصف فحص شامل 1...' },
    { title: 'فحص شامل 2', description: 'وصف فحص شامل 2...' },
    { title: 'فحص شامل 3', description: 'وصف فحص شامل 3...' },
  ];

  export const anotherPackageData = [
    { title: 'باقات أخرى 1', description: 'وصف باقات أخرى 1...' },
    { title: 'باقات أخرى 2', description: 'وصف باقات أخرى 2...' },
    { title: 'باقات أخرى 3', description: 'وصف باقات أخرى 3...' },
  ];