import React, { useState } from 'react';
import ConfirmationPopup from './ConfirmationPopup';

const Table = ({ data, headers, handleUpdate, handleDelete }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [actionType, setActionType] = useState(null); // 'delete' or 'update'
  const [selectedItemId, setSelectedItemId] = useState(null);

  // Handle opening the confirmation popup
  const openConfirmationPopup = (type, itemId) => {
    setActionType(type);
    setSelectedItemId(itemId);
    setIsConfirmOpen(true);
  };

  // Handle confirming the action (delete or update)
  const handleConfirm = async () => {
    setIsConfirmOpen(false);
    if (actionType === 'delete') {
      await handleDelete(selectedItemId); // Call the delete handler
    } else if (actionType === 'update') {
      await handleUpdate(selectedItemId); // Call the update handler
    }
    setSelectedItemId(null); // Clear the selected item ID
  };

  return (
    <div className="overflow-x-scroll">
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow">
        <thead className="bg-gray-100">
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600"
              >
                {header.replace(/_/g, ' ').toUpperCase()}
              </th>
            ))}
            <th className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-600">
              Operations
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="hover:bg-gray-50">
              {headers.map((header) => (
                <td key={header} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-800">
                  {typeof item[header] === 'object' && item[header] !== null
                    ? item[header]
                    : item[header] ? item[header].toString() : ''}
                </td>
              ))}
              <td className="py-3 px-4 border-b border-gray-200">
                <div className="flex justify-center gap-[15px] mb-2">
                  <button
                    className="bg-secondColor text-white py-1 px-3 rounded hover:bg-yellow-600"
                    onClick={() => openConfirmationPopup('update', item._id)}
                  >
                    Update
                  </button>
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    onClick={() => openConfirmationPopup('delete', item._id)}
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Confirmation Popup */}
      {isConfirmOpen && (
        <ConfirmationPopup
          message={`Are you sure you want to ${actionType === 'delete' ? 'delete' : 'update'} this item?`}
          onConfirm={handleConfirm}
          onCancel={() => setIsConfirmOpen(false)}
        />
      )}
    </div>
  );
};

export default Table;
