import React from 'react';
import LocationImage from "../../images/Assets/locaTion2.jpg";

import { FaFacebook, FaInstagram, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <section className="bg-backgroundColor2 bg-opacity-50 flex item-center justify-center p-8">
      {/* Main Container */}
      <div className={`container bg-white rounded-3xl shadow-lg p-5 max-w-4xl grid grid-cols-1 lg:grid-cols-3 items-center gap-8 ${isArabic ? 'flex-row-reverse' : ''}`}>
        
        {/* Right Section */}
        <div className={`  ${isArabic ? 'text-right  text-end' : 'text-left  text-start'}`}>
          <h2 className={`text-xl font-bold text-primeColor mb-4`}>
            {t('home.other.contact.socialMediaHeader')}
          </h2>

          <div className={`flex justify-center lg:justify-${isArabic ? 'start' : 'end'} gap-4 mb-6`}>
            <a href="https://www.instagram.com/alshifa.lab/" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-3xl text-pink-500 hover:scale-110 transition-transform" />
            </a>
            
            <a href="https://www.facebook.com/profile.php?id=100085742225186&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="text-3xl text-blue-600 hover:scale-110 transition-transform" />
            </a>
            
            <a href="https://api.whatsapp.com/send/?phone=971501344319&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp className="text-3xl text-green-500 hover:scale-110 transition-transform" />
            </a>
          </div>


          <h3 className={`text-lg font-semibold text-primeColor`}>
            {t('home.other.contact.contactUs')}
          </h3>
          <div className={`flex justify-center gap-[15px] lg:justify-${isArabic ? 'start' : 'end'} space-x-4 mt-2 text-lg`}>
            <a
              href="tel:0501344319"
              className="flex items-center flex-1 gap-2 hover:text-thirdColor hover:scale-110 transition duration-300 py-2 px-3 rounded-md bg-white shadow-sm border border-gray-300 w-full max-w-[300px] overflow-hidden"
              dir={isArabic ? "ltr" : "auto"} // Adjusting directionality based on the language
            >
              <FaPhoneAlt className={`text-[10px] ${isArabic ? 'transform scale-x-[-1]' : ''}`} />
              <span className="font-semibold text-[10px] text-secondColor break-words">
                0569972730
              </span>
            </a>

            <a
              href="tel:0501344319"
              className="flex items-center flex-1 gap-2 hover:text-thirdColor hover:scale-110 transition duration-300 py-2 px-3 rounded-md bg-white shadow-sm border border-gray-300 w-full max-w-[300px] overflow-hidden"
              dir={isArabic ? "ltr" : "auto"} // Adjusting directionality based on the language
            >
              <FaPhoneAlt className={`text-[10px] ${isArabic ? 'transform scale-x-[-1]' : ''}`} />
              <span className="font-semibold text-[10px] text-secondColor break-words">
                0501344319
              </span>
            </a>
          </div>



        </div>

        {/* Divider */}
        <div className="flex items-center justify-center flex-col sm:flex-row">
          <span className="text-2xl font-bold text-secondColor my-2 sm:my-0">{t('home.other.contact.divider')}</span>
        </div>

        {/* Left Section */}
        <div className="flex flex-col items-center">
          <img src={LocationImage} alt="Connect with us" className="mb-4 rounded-lg w-[600px] h-[200px] object-cover object-cover" />
          <Link to="/contact" className="bg-secondColor text-white py-2 px-6 rounded shadow-md hover:bg-opacity-80 transition duration-300">
            {t('home.other.contact.button')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Contact;
