// src/api.js
const BASE_URL = '/api/v1'; // Replace with your actual base URL

const API_ENDPOINTS = {
  PACKAGES: {
    GET_ALL: `${BASE_URL}/package`, // GET: Fetch all sales
    ADD: `${BASE_URL}/package`, // POST: Add a new sale
    UPDATE: (id) => `${BASE_URL}/package/find/${id}`, // PATCH: Update sale by ID
    DELETE: (id) => `${BASE_URL}/package/find/${id}`, // DELETE: Delete sale by ID
    GET_ONE: (id) => `${BASE_URL}/package/find/${id}` // GET: Fetch one sale by ID
 },
  Category: {
    GET_ALL: `${BASE_URL}/packagecategory`,
    ADD: `${BASE_URL}/packagecategory`,
    UPDATE: (id) => `${BASE_URL}/packagecategory/find/${id}`,
    DELETE: (id) => `${BASE_URL}/packagecategory/find/${id}`,
    GET_BY_ID: (id) => `${BASE_URL}/packagecategory/find/${id}`,
  },
  OPINIONS: {
    GET_ALL: `${BASE_URL}/opinion`, // GET: Fetch all sales
    ADD: `${BASE_URL}/opinion`, // POST: Add a new sale
    UPDATE: (id) => `${BASE_URL}/opinion/find/${id}`, // PATCH: Update sale by ID
    DELETE: (id) => `${BASE_URL}/opinion/find/${id}`, // DELETE: Delete sale by ID
    GET_ONE: (id) => `${BASE_URL}/opinion/find/${id}` // GET: Fetch one sale by ID
  },
  OFFERS:{
    GET_ALL: `${BASE_URL}/sale`, // GET: Fetch all sales
    ADD: `${BASE_URL}/sale`, // POST: Add a new sale
    UPDATE: (id) => `${BASE_URL}/sale/find/${id}`, // PATCH: Update sale by ID
    DELETE: (id) => `${BASE_URL}/sale/find/${id}`, // DELETE: Delete sale by ID
    GET_ONE: (id) => `${BASE_URL}/sale/find/${id}` // GET: Fetch one sale by ID
  },
  INFOS: {
    GET_ALL: `${BASE_URL}/medicalinfo`, // GET: Fetch all sales
    ADD: `${BASE_URL}/medicalinfo`, // POST: Add a new sale
    UPDATE: (id) => `${BASE_URL}/medicalinfo/find/${id}`, // PATCH: Update sale by ID
    DELETE: (id) => `${BASE_URL}/medicalinfo/find/${id}`, // DELETE: Delete sale by ID
    GET_ONE: (id) => `${BASE_URL}/medicalinfo/find/${id}` // GET: Fetch one sale by ID
  },
  MAILS:{
    REPORT: `${BASE_URL}/mail/report`,          // POST: Send report email
    WITHDRAWAL: `${BASE_URL}/mail/withdrawal`,  // POST: Send withdrawal email with file uploads
    BOOKING: `${BASE_URL}/mail/booking`,        // POST: Send booking email
    CONTACT: `${BASE_URL}/mail/sentContact`     // POST: Send contact email
  }
  // Add more endpoints as needed
};

// http://localhost:4200
  // https://alshifa-back-5rtn.vercel.app

  // https://www.instagram.com/alshifa.lab/
  // https://www.facebook.com/profile.php?id=100085742225186&mibextid=ZbWKwL
  // https://api.whatsapp.com/send/?phone=971501344319&text&type=phone_number&app_absent=0
  // 0501344319
  // 0569972730
  // asmc.lab@gmail.com
  // https://www.google.com/maps/place/Alshifa+medical+laboratory/@25.3429584,56.3490257,17z/data=!3m1!4b1!4m6!3m5!1s0x3ef44f82e0f0798d:0xcbf6eba9f41c3fcb!8m2!3d25.3429584!4d56.3490257!16s%2Fg%2F11kj_8l5q_?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D


export default API_ENDPOINTS;
