import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TimePicker.scss'; // Import your Sass file

const MyTimePicker = ({ holder, width, onChange, dateSelected }) => {
  const [selectedTime, setSelectedTime] = useState(null); // Manage selected time state

  useEffect(() => {
    // Reset time if date changes
    if (dateSelected) {
      setSelectedTime(null);
    }
  }, [dateSelected]);

  const handleTimeChange = (time) => {
    if (time) {
      // Combine dateSelected and time
      const selectedDateWithTime = new Date(dateSelected);
      selectedDateWithTime.setHours(time.getHours());
      selectedDateWithTime.setMinutes(time.getMinutes());

      setSelectedTime(selectedDateWithTime); // Update local state
      onChange(selectedDateWithTime); // Trigger parent's onChange handler with the combined date and time
    }
  };

  // Pass a function to timeClassName that returns a class
  const timeClassName = (time) => {
    // Example: Apply custom styles for selected time or other conditions
    const currentHour = time.getHours();
    if (currentHour === selectedTime?.getHours()) {
      return 'react-datepicker__time-list-item--selected'; // Custom class for selected time
    }
    return 'react-datepicker__time-list-item'; // Default class for other times
  };

  return (
    <div className={` col-span-3 w-full ${width ? `md:w-[${width}%]` : 'md:w-full'} `}>
      {holder && <label className="block mb-1 text-lg font-semibold text-gray-700">{holder}</label>}
      <DatePicker
        selected={selectedTime} // Controlled component with local state
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60} // Selectable every hour
        timeCaption="Time"
        dateFormat="h:mm aa"
        className="w-full p-3 rounded-lg border border-gray-300 focus:border-primeColor focus:ring-2 focus:ring-primeColor focus:outline-none transition-all duration-300 shadow-md"
        placeholderText={holder}
        disabled={!dateSelected} // Disable time picker if no date is selected
        calendarClassName="react-datepicker w-full" // Custom classes for the popup
        timeClassName={timeClassName} // Pass the function here instead of a string
        wrapperClassName={`react-datepicker-wrapper ${width ? `w-${width}` : 'w-full'}`} // Use dynamic width
      />
    </div>
  );
};

export default MyTimePicker;
