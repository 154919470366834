import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Table from './Table'; // Reuse the Table component
import { useFetch } from '../../hooks/useFetch';
import TableLoading from '../Loading/TableLoading';
import API_ENDPOINTS from '../../api';

const OpinionManagement = () => {
  const [opinions, setOpinions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { data: opinionsData, loading, error: opinionsError } = useFetch(API_ENDPOINTS.OPINIONS.GET_ALL);
  
  useEffect(() => {
    if (opinionsData && Array.isArray(opinionsData.opinion)) {
      setOpinions(opinionsData.opinion);
    }
  }, [opinionsData]);

  const handleUpdate = (id) => {
    if (id) {
      navigate(`/admin/addOpinion/${id}`);
    } else {
      console.error('ID is undefined');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(API_ENDPOINTS.OPINIONS.DELETE(id));
      // Update the state after deleting
      setOpinions((prevOpinions) => prevOpinions.filter(opinion => opinion._id !== id));
    } catch (error) {
      console.error('Failed to delete opinion:', error);
    }
  };

  const opinionHeaders = ['image', 'altText'];
  const offerHeaders = opinionHeaders.filter(key =>
    opinions.some(opinion => opinion[key] !== null && opinion[key] !== '' && opinion[key] !== undefined)
  );

  if (loading) return <div className="text-center"><TableLoading /></div>;

  return (
    <div>
      <h2 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">Opinion Management</h2>
      
      {/* Add Opinion button */}
      <Link to="/admin/addOpinion">
        <button className="bg-secondColor hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">
          Add Opinion
        </button>
      </Link>

      {/* Display Table */}
      <Table
        data={opinions.map(opinion => ({
          ...opinion,
          image: <img src={opinion.image} alt={opinion.altText} width="100" />, // Render image with alt text
          altText: opinion.altText || 'Opinion image', // Default to 'Opinion image' if altText is not provided
        }))}
        headers={offerHeaders}
        handleUpdate={(id) => handleUpdate(id)} // Pass the correct ID
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default OpinionManagement;
