import React, { useState } from 'react'
import RandomService from '../../component/Home/RandomServices'
import RandomPackage from '../../component/Home/RandomPackage'
import Contact from '../../component/Home/Contact'
import Info from '../../component/Home/Info'
import Request from '../../component/Home/Request'
import './home.scss'
import LandingPage2 from './LandingPage2'

import { FaFacebook, FaInstagram, FaMapMarkedAlt, FaWhatsapp, FaPoll, FaPlus, FaShareAlt } from 'react-icons/fa'; // Import the survey icon

const socialLinks = [
  {
    href: 'https://www.instagram.com/alshifa.lab/',
    icon: <FaInstagram />,
    bgColor: 'bg-pink-500',
    name: 'Instagram'
  },
  {
    href: 'https://facebook.com',
    icon: <FaFacebook />,
    bgColor: 'bg-blue-600',
    name: 'Facebook'

  },
  
  {
    href: 'https://api.whatsapp.com/send/?phone=971501344319&text&type=phone_number&app_absent=0',
    icon: <FaWhatsapp />,
    bgColor: 'bg-green-500',
    name: 'Whatsapp'

  },
  {
    href: 'https://maps.app.goo.gl/7hsKaWyj28ZKFGpZ7',
    icon: <FaMapMarkedAlt />,
    bgColor: 'bg-red-500',
    name: 'Map'

  },
  {
    href: 'https://surveyheart.com/form/5e89c1aef411e847dc5706ca',
    icon: <FaPoll />,
    bgColor: 'bg-purple-500',
    name: 'Survey'

  },
];

const FloatingSocialMedia = () => {
  const [hovered, setHovered] = useState(false); // State to control hover
  const [hoveredIcon, setHoveredIcon] = useState(null);

  return (
    <div 
    className="fixed right-[30px] bottom-[30px] flex flex-col items-end"
    onMouseEnter={() => setHovered(true)} // Set hovered state to true on mouse enter
    onMouseLeave={() => setHovered(false)} // Set hovered state to false on mouse leave
    dir='ltr'
  >
    {/* Expanded Icons */}
    <div className={`flex flex-col items-end space-y-3 transition-opacity duration-300 ${hovered ? 'block' : 'hidden'}`}>
  {socialLinks.map((link, index) => (
    <div
      key={index}
      className="flex items-end gap-[10px]" // Use items-center for vertical alignment
      onMouseEnter={() => setHoveredIcon(index)} // Set hovered icon index
      onMouseLeave={() => setHoveredIcon(null)} // Remove hovered icon index
    >
      {/* Display name to the left of the icon when hovered */}
      <span className={`text-white bg-gray-700 px-3 py-1 rounded-md shadow-lg ${hoveredIcon === index ? 'block' : 'hidden'} transition-all duration-300`}>
        {link.name}
      </span>
      <a
        href={link.href}
        target="_blank"
        rel="noopener noreferrer"
        className={`${link.bgColor} text-white p-3 rounded-full transition-transform transform hover:scale-110 hover:-translate-y-1`}
      >
        {link.icon}
      </a>
    </div>
  ))}
</div>
    {/* Main Floating Icon */}
    <div 
      className={`bg-secondColor text-white p-3 rounded-full transition-transform transform ${hovered ? 'scale-110' : ''}`}
      title="More Options"
    >
      <FaShareAlt />
    </div>
  </div>
  );
};

const Home = () => {
  return (
    <div className='min-h-[90vh] bg-backgroundColor2 home'>
      <LandingPage2/>
      <RandomService/>
      <RandomPackage />
      <Contact/>
      <Info/>
      <Request/>
      <FloatingSocialMedia/>

    </div>
  )
}

export default Home