import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import InputField from '../../pages/Forms/InputField';
import FileUpload from './FileUpload';
import { FaFileUpload } from 'react-icons/fa';
import { useFetch } from '../../hooks/useFetch';
import MyDatePicker from '../Datepicker/MyDatePicker';
import TextArea from '../../pages/Forms/TextArea';
import SpinnerLoading from '../Loading/SpinnerLoading';
import ConfirmationPopup from '../../pages/Forms/Confirm';
import SendingStatusPopup from '../../pages/Forms/SendingStatusPopup';
import API_ENDPOINTS from '../../api';
const AddPackage = () => {
  const { packageId } = useParams();
  const [newPackage, setNewPackage] = useState({
    name_en: '',
    name_ar: '',
    description_en: '',
    description_ar: '',
    tests: '',
    imagePackage: null,
    price: '',
    priceSale: '',
    salePercent: '',
    startDate: '',
    endDate: '',
    onSale: false,
    categoryId: '',
  });
  const navigate = useNavigate(); 
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false); 
  const [sendingStatus, setSendingStatus] = useState('');
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(API_ENDPOINTS.Category.GET_ALL);
        setCategories(data.categories);
      } catch (err) {
        setError('Failed to load categories');
      }
    };
    fetchCategories();
  }, []);

  // Fetch package data if editing
  useEffect(() => {
    if (packageId) {
      setIsEditing(true);
      const fetchPackage = async () => {
        try {
          const { data } = await axios.get(API_ENDPOINTS.PACKAGES.GET_ONE(packageId));
          setNewPackage({
            name_en: data.data.name_en,
            name_ar: data.data.name_ar,
            description_en: data.data.description_en,
            description_ar: data.data.description_ar,
            tests: data.data.tests,
            price: data.data.price,
            priceSale: data.data.priceSale || '',
            salePercent: data.data.salePercent || '',
            startDate: data.data.startDate || '',
            endDate: data.data.endDate || '',
            onSale: data.data.onSale || false,
            categoryId: data.data.categoryId._id,
            testsSale: data.data.testsSale || '',
            descriptionSale_en: data.data.descriptionSale_en || '',
            descriptionSale_ar: data.data.descriptionSale_ar || '',
          });
        } catch (err) {
          setError('Failed to load package details');
        }
      };
      fetchPackage();
    }
  }, [packageId]);

  const handlePackageChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewPackage((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewPackage((prev) => ({ ...prev, imagePackage: file }));
    }
  };

  const handleStartDateSelect = (date) => {
    setNewPackage((prev) => ({ ...prev, startDate: date }));
  };

  const handleEndDateSelect = (date) => {
    setNewPackage((prev) => ({ ...prev, endDate: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsConfirmOpen(true);
  };

  const handleConfirmSubmit = async () => {
    setIsConfirmOpen(false);
    setSendingStatus('sending');

    const formData = new FormData();
    formData.append('name_en', newPackage.name_en);
    formData.append('name_ar', newPackage.name_ar);
    formData.append('description_en', newPackage.description_en);
    formData.append('description_ar', newPackage.description_ar);
    formData.append('tests', newPackage.tests);
    formData.append('price', newPackage.price);
    formData.append('onSale', newPackage.onSale);

    if (newPackage.onSale) {
      formData.append('priceSale', newPackage.priceSale);
      formData.append('salePercent', newPackage.salePercent);
      formData.append('startDate', newPackage.startDate);
      formData.append('endDate', newPackage.endDate);
      formData.append('testsSale', newPackage.testsSale);
      formData.append('descriptionSale_en', newPackage.descriptionSale_en);
      formData.append('descriptionSale_ar', newPackage.descriptionSale_ar);
    }

    formData.append('categoryId', newPackage.categoryId);

    if (newPackage.imagePackage) {
      formData.append('imagePackage', newPackage.imagePackage);
    }

    try {
      if (isEditing) {
        await axios.patch(API_ENDPOINTS.PACKAGES.UPDATE(packageId), formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        await axios.post(API_ENDPOINTS.PACKAGES.ADD, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      setSendingStatus('success');
    } catch (err) {
      setSendingStatus('failure');
    }
  };

  const handleCancelSubmit = () => {
    setIsConfirmOpen(false);
  };

  const handleClosePopup = () => {
    if (sendingStatus === 'success') {
      navigate('/admin/packages');
    }
    setSendingStatus('');
  };

  if (sendingStatus === 'sending') return <SpinnerLoading />;

  
  return (
    <div className="p-6 bg-white rounded-md shadow-md">
      <h3 className="text-2xl font-extrabold text-primeColor mb-4 border-b-2 border-primeColor pb-2">{isEditing ? 'Edit Package' : 'Add New Package'}</h3>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 text-lg font-semibold text-gray-700">Select Package Category</label>
          <select
            name="categoryId"
            value={newPackage.categoryId}
            onChange={handlePackageChange}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
            required
          >
            <option value="" disabled>Select a category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.title_en}
              </option>
            ))}
          </select>
        </div>

        <InputField label="Package Name (English)" type="text" name="name_en" value={newPackage.name_en} onChange={handlePackageChange} required />
        <InputField label="Package Name (Arabic)" type="text" name="name_ar" value={newPackage.name_ar} onChange={handlePackageChange} required />
        <TextArea label="Description (English)" name="description_en" value={newPackage.description_en} onChange={handlePackageChange} required />
        <TextArea label="Description (Arabic)"  name="description_ar" value={newPackage.description_ar} onChange={handlePackageChange} required />

        <InputField label="Number of Tests" type="number" name="tests" value={newPackage.tests} onChange={handlePackageChange} required />
        <InputField label="Price" type="number" name="price" value={newPackage.price} onChange={handlePackageChange}  />
        <FileUpload file={newPackage.imagePackage} fileType="imagePackage" label="Upload Package Image" handleFileUpload={handleFileUpload} Icon={<FaFileUpload className="text-primeColor w-5 h-5" />} />

        {/* Sale Fields */}
<div className="flex items-center mt-4">
  <label className="flex items-center space-x-3 text-lg">
    <input
      type="checkbox"
      name="onSale"
      checked={newPackage.onSale}
      onChange={handlePackageChange}
      className="form-checkbox h-5 w-5 text-primeColor rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-primeColor"
      id="onSaleCheckbox"
    />
    <span className="text-gray-700">On Sale</span>
  </label>
</div> 


{newPackage.onSale && (
  <>
    <InputField label="Sale Price" type="number" name="priceSale" value={newPackage.priceSale} onChange={handlePackageChange}  />
    <InputField label="Sale Percent (%)" type="number" name="salePercent" value={newPackage.salePercent} onChange={handlePackageChange} />
    <MyDatePicker holder="Sale Start Date" selectedDate={newPackage.startDate} onChange={handleStartDateSelect} />
    <MyDatePicker holder="Sale End Date" selectedDate={newPackage.endDate} onChange={handleEndDateSelect} />
    <InputField label="Number of Tests On Sale" type="number" name="testsSale" value={newPackage.testsSale} onChange={handlePackageChange} required />
    <TextArea label="Sale Description (English)"  name="descriptionSale_en" value={newPackage.descriptionSale_en} onChange={handlePackageChange}  />
    <TextArea label="Sale Description (Arabic)"  name="descriptionSale_ar" value={newPackage.descriptionSale_ar} onChange={handlePackageChange}  />
  </>
)}


        <button type="submit" className="px-4 py-2 font-bold text-white bg-primeColor rounded-lg hover:bg-green-700">
         {sendingStatus==="sending"? "Loading...." :(isEditing ? 'Update Package' : 'Add Package')}
        </button>
      </form>

      {/* Confirmation Popup */}
      {isConfirmOpen && (
        <ConfirmationPopup 
          onConfirm={handleConfirmSubmit} 
          onCancel={handleCancelSubmit} 
        />
      )}

      {/* Sending Status Popup */}
      {sendingStatus && (
        <SendingStatusPopup status={sendingStatus} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default AddPackage;
