// PartnerLogos.js
import React from "react";

const PartnerLogos = ({ partnerLogos ,t }) => (
    <div className="absolute bottom-[-50px]  left-0 right-0">
      <h2 className="text-center text-secondColor font-large font-arabic font-bold text-gray-800">
        {t("home.landingPage.partnerLogos.title")}
      </h2>
      <div className="flex bg-white justify-center mx-auto space-x-4 rounded overflow-hidden w-[80vw]  ">
  
  
        {/* First scrolling div */}
        <div className="flex gap-[20px] relative animate-scroll-1">
          {partnerLogos.map((logo, i) => (
            <img
              key={i}
              src={logo}
              alt={`Partner ${i + 1}`}
              className="h-[100px] object-contain opacity-80 hover:opacity-100 transition-opacity duration-300"
            />
          ))}
        </div>
  
  
        {/* Second scrolling div with different animation */}
        {/* <div className="flex gap-[20px] animate-scroll-1 " aria-hidden="true">
          {partnerLogos.map((logo, i) => (
            <img
              key={i}
              src={logo}
              alt={`Partner ${i + 1}`}
              className="h-[100px] object-contain opacity-80 hover:opacity-100 transition-opacity duration-300"
            />
          ))}
        </div> */}
  
  
      </div>
    </div>
  );

export default PartnerLogos;
