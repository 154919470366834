import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Details from '../../component/Package/Details';
import { useTranslation } from 'react-i18next';
import imageSurvey from '../../images/Assets/feedback-3709752_1920.jpg'
import { FaClock, FaPercentage, FaMoneyBillWave } from 'react-icons/fa'; // Import icons
import moment from 'moment'; // Import moment for date formatting

const PackageDetails = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const packageData = location.state || {};
  console.log(packageData);
  

  const { 
    description_en,
    description_ar,
    endDate,
    image,
    image_en,
    image_ar,
    name_en,
    name_ar,
    offerDuration,
    salePercent,
    salePrice,
    priceSale,
    startDate,
    onSale,
    price,
    tests,
    testsSale,
    descriptionSale_ar,
    descriptionSale_en,
  } = packageData;

  const isOffer = salePrice && offerDuration;
  const isPackageSale = onSale && true;
  const name = i18n.language === 'ar' ? name_ar : name_en;
  const description = i18n.language === 'ar' ? description_ar : description_en;
  const descriptionSale = i18n.language === 'ar' ? descriptionSale_ar : descriptionSale_en;

  // Calculate time left for sale end
  const timeLeft = endDate ? Math.ceil((new Date(endDate) - new Date()) / (1000 * 60 * 60 * 24))+1 : null;
  
  const Duration = endDate ? Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24))+1 : null;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="relative w-full h-72 overflow-hidden rounded-lg shadow-lg mx-auto mt-6">
        <img
          src={image ? image : (i18n.language === 'ar' ? image_ar[0] : image_en[0]) || 'https://via.placeholder.com/1200x300'}
          alt={name}
          className="w-full h-full object-cover brightness-50"
        />

        {/* Badges */}
        <div className="absolute top-4 left-4">
          {isPackageSale && (
            <span className="bg-red-500 text-white text-sm font-semibold px-3 py-1 rounded-md shadow-lg">
              {t('details.onSale')}
            </span>
          )}
          {isOffer && (
            <span className="bg-yellow-500 text-white text-sm font-semibold px-3 py-1 rounded-md shadow-lg ml-2">
              {t('details.specialOffer')}
            </span>
          )}
        </div>

        {/* Title Overlay */}
        <div className="absolute w-full h-full inset-0 flex items-center justify-center">
          <div className="bg-black bg-opacity-50 p-4 rounded-md">
            <h2 className="text-4xl font-extrabold text-white text-center">
              {name || (i18n.language === 'ar' ? 'لايوجد بافة او عرض للحجز' : 'Not Found Any Package Or Offer For Booking')}
            </h2>
          </div>
        </div>
      </div> 

      <main className="mt-10 container mx-auto p-8 bg-white shadow-xl rounded-lg space-y-6">
        <Details
          title={name}
          description={description}
          salePrice={ salePrice || priceSale}
          salePercent={salePercent}
          offerDuration={ Duration}
          price={!isOffer ? price : null}
          tests={tests}
          onSale={isPackageSale ? onSale : null}
          image={image}
          packageData={packageData}
          timeLeft={timeLeft} // Pass time left to Details component
          startDate={startDate}
          endDate={endDate}
          descriptionSale={descriptionSale}
          testsSale={testsSale}
        />
      </main>

      {/* Survey Section */}
<section className="w-[80%] mx-auto mb-6 mt-12 p-6 bg-white shadow-lg rounded-lg flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6 transition-shadow duration-500 hover:shadow-2xl">
  {/* Survey Image */}
  <div className="w-full md:w-1/2 overflow-hidden rounded-lg">
    <img
      src={imageSurvey}
      alt="Survey"
      className="w-full h-full object-cover"
    />
  </div>
  {/* Survey Text */}
  <div className="w-full md:w-1/2 text-center md:text-left">
    <h2 className="text-3xl font-bold text-primeColor mb-4">
      {t('survey.title') || 'شارك في استطلاع الرأي الخاص بنا'}
    </h2>
    <p className="text-lg text-gray-700 mb-6">
      {t('survey.description') ||
        'نود أن نسمع رأيك حول تجربتك مع باقتنا. الرجاء مساعدتنا في تحسين خدماتنا من خلال المشاركة في هذا الاستطلاع القصير.'}
    </p>
    {/* Survey Button */}
    <a 
      href="https://surveyheart.com/form/5e89c1aef411e847dc5706ca" 
      target="_blank" // Opens the link in a new tab
      rel="noopener noreferrer" // Security measure
    >
      <button className="px-8 py-3 bg-primeColor text-white font-semibold rounded-full shadow-lg hover:bg-secondColor transition-colors duration-300">
        {t('survey.start') || 'ابدأ الاستطلاع'}
      </button>
    </a>
  </div>
</section>
    </div>
  );
};

export default PackageDetails;
