import React from 'react';

const ConfirmationPopup = ({ onConfirm, onCancel }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div className="bg-gradient-to-b from-white to-gray-50 p-8 rounded-xl shadow-2xl w-[90%] md:w-[400px]">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
        تأكيد الإرسال
      </h2>
      <p className="text-gray-600 mb-8 text-center">
        هل أنت متأكد من رغبتك في إرسال بياناتك  ؟
      </p>
      <div className="flex justify-center space-x-4 rtl:space-x-reverse">
        <button
          className="bg-green-500 text-white py-3 px-8 rounded-full font-medium shadow-lg hover:bg-green-600 transition duration-300 transform hover:scale-105"
          onClick={onConfirm}
        >
          نعم، تأكيد
        </button>
        <button
          className="bg-red-500 text-white py-3 px-8 rounded-full font-medium shadow-lg hover:bg-red-600 transition duration-300 transform hover:scale-105"
          onClick={onCancel}
        >
          إلغاء
        </button>
      </div>
    </div>
  </div>
);

export default ConfirmationPopup;
