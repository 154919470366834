import React from 'react';
import { FaClock, FaPercentage, FaMoneyBillWave, FaCalendarAlt, FaFlask } from 'react-icons/fa'; 
import moment from 'moment'; 
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../i18n';

const Details = ({ 
  packageSale, 
  specialOffer, 
  title, 
  descriptionSale, 
  description, 
  salePrice, 
  salePercent, 
  offerDuration, 
  price, 
  testsSale, 
  tests, 
  timeLeft, 
  startDate, 
  endDate, 
  packageData 
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(packageData.onSale);
  const isArabic = i18n.language === 'ar';

  
  // Format dates using moment
  const formattedStartDate = packageData.startDate ? moment(packageData.startDate).format(isArabic?'YYYY  MMM  DD':"DD MMM YYYY") : null;
  const formattedEndDate = packageData.endDate ? moment(packageData.endDate).format(isArabic?'YYYY MMM  DD':"DD MMM YYYY") : null;

  const handleBooking = () => {
    navigate('/package/booking', { state: packageData });
  };

  // Check if price-related information exists
  const hasPriceInfo = salePrice || price || salePercent;

  return (
    <div className={`grid grid-cols-1 lg:grid-cols-2 gap-6 p-8 rounded-lg shadow-lg ${specialOffer ? 'bg-yellow-50' : 'bg-white'} animate-fadeIn relative`}>

      {/* Offer Badge */}
      <div className="absolute top-4 right-4">
        {packageSale && (
          <span className="bg-green-600 text-white text-xs font-semibold px-4 py-2 rounded-full">
            {t('offer.packageOffer')}
          </span>
        )}
        {specialOffer && (
          <span className="bg-yellow-600 text-white text-xs font-semibold px-4 py-2 rounded-full">
            {t('offer.specialOffer')}
          </span>
        )}
      </div>

      {/* Pricing Section */}
      {hasPriceInfo && (
        <div className={`flex flex-col justify-center items-center text-start p-6 rounded-lg ${specialOffer ? 'border-yellow-600' : 'border-secondColor'} border-l-4 shadow-md hover:shadow-xl`}>
          {(salePrice || price) &&
            (<>
              <h3 className="text-3xl font-bold text-yellow-600 mb-2">
            <FaMoneyBillWave className="inline-block mr-2" /> {t('details.currentPrice')}
          </h3>
          <div className="text-5xl font-bold text-primeColor mb-4">
            {salePrice || price} {t("currency")} 
          </div>
          {salePrice && price && (
            <div className="text-xl text-gray-600 line-through mb-2">
              {price} {t("currency")}
            </div>
          )}
            </>)
          }

          {salePercent && (
            <div className={`flex gap-[15px] items-center  text-green-600 mb-2 ${!(salePrice || price)?"text-3xl":"text-lg"}`}>
              <FaPercentage className="inline-block mr-2" /> {t('details.discount')} {salePercent}%
            </div>
          )}

          {timeLeft>=1 && (
            <div className="flex gap-[15px] items-center text-md text-red-500 mb-4">
              <FaClock className="inline-block mr-2" /> {t('details.timeLeft')}:{timeLeft } {t("days")}
            </div>
          )}
          {/* Booking Button */}
          {packageData&&
            (<button
            onClick={handleBooking}
            className="bg-secondColor text-white py-2 px-4 rounded transition-all duration-500 shadow-lg hover:bg-secondColor/90 focus:outline-none focus:ring-2 focus:ring-secondColor"
          >
            {t('packages.bookNow')}
          </button>)}
        </div>
      )}

      {/* Details Section */}
      <div className={`animate-fadeIn ${!hasPriceInfo ? "col-span-2" : "col-span-1"}`}>
        <h2 className="text-3xl font-bold text-secondColor mb-4 border-b-2 border-secondColor pb-2">
          {t('details.details')}
        </h2>

        {/* Description Section */}
        {(descriptionSale || description) && (
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border-l-4 border-secondColor mb-6">
            <p className="text-lg text-gray-700 leading-relaxed">
              {packageSale ? descriptionSale : description}
            </p>
          </div>
        )}

        {/* Display Tests */}
        {(tests && !packageData.onSale) && (
          <div className="flex items-center gap-2 mb-4">
            <FaFlask className="text-secondColor text-xl" />
            <div className="text-lg text-gray-600">
              {t('details.testsNumber')}: <span className="font-semibold text-secondColor">{tests}</span>
            </div>
          </div>
        )}

        {testsSale && packageData.onSale && (
          <div className="flex items-center gap-2 mb-4">
            <FaFlask className="text-secondColor text-xl" />
            <div className="text-lg text-gray-600">
              {t('details.testsNumberSale')}: <span className="font-semibold text-secondColor">{testsSale}</span>
            </div>
          </div>
        )}

        {/* Display Dates and Offer Duration */}
        {(startDate || endDate || offerDuration) && (
          <div className="flex flex-col gap-4 mt-6 bg-white p-4 rounded-lg shadow-sm border border-gray-200">
            {startDate && (
              <div className="flex items-center gap-2">
                <FaCalendarAlt className="text-blue-500 text-xl" />
                <div className="text-lg text-gray-700">
                  {t('details.start')}: <span className="font-medium">{formattedStartDate} </span>
                </div>
              </div>
            )}
            {endDate && (
              <div className="flex items-center gap-2">
                <FaCalendarAlt className="text-blue-500 text-xl" />
                <div className="text-lg text-gray-700">
                  {t('details.end')}: <span className="font-medium">{formattedEndDate}</span>
                </div>
              </div>
            )}
            {offerDuration && (
              <div className="flex items-center gap-2">
                <FaClock className="text-yellow-500 text-xl" />
                <div className="text-lg text-gray-700">
                {t('details.offerDuration')}: 
<span className="font-medium">
  {offerDuration} {offerDuration > 1 ? t("days") : t("day")}
</span>
                </div>
              </div>
            )}

            
          </div>
        )}

        {/* Booking Button (if no price info) */}
        {!hasPriceInfo&&(
              <button
                onClick={handleBooking}
                className="bg-secondColor mt-6 text-white px-4 py-2 rounded transition-all duration-500 shadow-lg hover:bg-secondColor/90 focus:outline-none focus:ring-2 focus:ring-secondColor"
              >
                {t('packages.bookNow')}
              </button>
            )}
      </div>
    </div>
  );
};

export default Details;
