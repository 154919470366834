import React from 'react';

const InformationLoading = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200 relative overflow-hidden animate-pulse">
      {/* Simulated category tag */}
      <div className="absolute top-4 right-4 h-6 w-16 bg-gray-300 rounded"></div>

      {/* Simulated title */}
      <div className="h-6 bg-gray-300 rounded w-3/4 mb-4 mt-6"></div>

      {/* Simulated description lines */}
      <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
      <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
      <div className="h-4 bg-gray-300 rounded w-4/6 mb-2"></div>

      {/* Background gradient for style consistency */}
      <div className="absolute inset-0 bg-gradient-to-r from-primeColor to-transparent opacity-20 transform -translate-x-1/2 -translate-y-1/2" />
    </div>
  );
};

export default InformationLoading;
