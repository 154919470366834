import React from 'react';
import { useTranslation } from 'react-i18next'; // Import i18n hook

const GeneralInfo = ({ generalInfo }) => {
  const { t, i18n } = useTranslation(); // Access translation hook

  return (
    <div className="grid gap-8 grid-cols-1 md:grid-cols-1 lg:grid-cols-2 max-w-6xl mx-auto px-4 py-8">
      {generalInfo.map((info) => {
        // Check the current language and display the appropriate title/description
        const title = i18n.language === 'ar' ? info.title_ar : info.title_en;
        const description = i18n.language === 'ar' ? info.description_ar : info.description_en;

        return (
          <div
            key={info._id}
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-gray-200 hover:bg-gray-50 relative overflow-hidden"
          >
            {/* Display category with translated labels */}
            <span className="absolute top-4 right-4 text-sm bg-primeColor text-white px-2 py-1 rounded">
              {info.category === 'weekly' ? t('weeklyTip') : t('medicalInformation.generalTab')}
            </span>

            {/* Title based on selected language */}
            <h2 className="text-2xl font-semibold text-secondColor mb-2 pt-6">
              {title}
            </h2>

            {/* Description based on selected language */}
            <p className="text-gray-700 leading-relaxed mb-4">
              {description}
            </p>

            <div className="absolute inset-0 bg-gradient-to-r from-primeColor to-transparent opacity-20 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        );
      })}
    </div>
  );
};

export default GeneralInfo;
