import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { FaFilePdf, FaIdCard, FaClipboardCheck } from 'react-icons/fa';
import InputField from './InputField';
import MyDatePicker from '../../component/Datepicker/MyDatePicker';
import MyTimePicker from '../../component/Datepicker/MyTimePicker'; // Time picker for time selection
import axios from 'axios';
import TextArea from './TextArea';
import ConfirmationPopup from './Confirm';
import SendingStatusPopup from './SendingStatusPopup';
import API_ENDPOINTS from '../../api';
import i18n from '../../i18n';

const FileUpload = ({ file, label, fileType, handleFileUpload, Icon }) => {
  const isImage = (file) => file?.type.startsWith('image/');
  const isPdf = (file) => file?.type === 'application/pdf';

  return (
    <div className="flex col-span-3 items-center justify-between w-full border p-4 rounded-lg shadow-md bg-white transition-transform duration-300 mb-4">
      <input
        type="file"
        id={fileType}
        accept="image/*, .pdf"
        onChange={(e) => handleFileUpload(e, fileType)}
        className="hidden"
      />
      <label
        htmlFor={fileType}
        className="flex items-center space-x-2 cursor-pointer border border-gray-300 rounded-lg p-2 transition-transform duration-300 hover:border-primeColor hover:scale-105"
      >
        <Icon className="text-primeColor w-5 h-5" />
        <span className="text-gray-700 font-semibold">{label}</span>
      </label>
      {file && (
        <div className="flex items-center gap-[5px] space-x-2">
          <span className="text-gray-700 truncate border-b-2 border-gray-300 pb-1 font-medium">
            {file.name}
          </span>
          {isImage(file) ? (
            <img
              src={URL.createObjectURL(file)}
              alt={label}
              className="w-[50px] h-[50px] object-cover rounded border border-gray-300 shadow-md"
            />
          ) : (
            isPdf(file) && <FaFilePdf className="text-red-500 w-[50px] h-[50px]" />
          )}
        </div>
      )}
    </div>
  );
};
 
const Withdrawl = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    suggestions: '',
    identityCard: null,
    insuranceCard: null,
    appointmentDate: null,
    appointmentdateTime: null, // Add appointmentDateTime to state
  });

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [sendingStatus, setSendingStatus] = useState(null);
  const [resetDate, setResetDate] = useState(false); // State for resetting the date

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      suggestions: '',
      identityCard: null,
      insuranceCard: null,
      appointmentDate: null,
      appointmentdateTime: null, // Reset appointmentDateTime
    });
  };

  const formatAppointmentDateTime = (dateTime) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    const date = new Date(dateTime);

    const day = daysOfWeek[date.getDay()];
    const formattedDate = `${date.getDate()} / ${months[date.getMonth()]} / ${date.getFullYear()}`;

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedTime = `${hours}:${minutes} ${period}`;

    return { day, formattedDate, formattedTime };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e, fileType) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({ ...prev, [fileType]: file }));
    }
  };

  const handleDateSelect = (date) => {
    setFormData((prev) => ({ ...prev, appointmentDate: date }));
  };

  const handleTimeSelect = (time) => {
    setFormData((prev) => ({ ...prev, appointmentdateTime: time }));
  };

  const handleSubmit = async () => {
    setShowConfirmPopup(false);
    setSendingStatus('sending');

    // Format the appointment date and time
    const formattedAppointment = formatAppointmentDateTime(formData.appointmentdateTime);

    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phone', formData.phone);
    data.append('suggestions', formData.suggestions);
    data.append('lang', i18n.language );

    // Append formatted date and time
    if (formattedAppointment) {
      data.append('appointmentDay', formattedAppointment.day);
      data.append('appointmentDate', formattedAppointment.formattedDate);
      data.append('appointmentTime', formattedAppointment.formattedTime);
    }

    if (formData.identityCard) {
      data.append('identityCard', formData.identityCard);
    }
    if (formData.insuranceCard) {
      data.append('insuranceCard', formData.insuranceCard);
    }

    // Log the FormData contents for debugging
    for (let [key, value] of data.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await axios.post(API_ENDPOINTS.MAILS.WITHDRAWAL, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Form submitted successfully', response.data);
      setSendingStatus('success');
      resetForm();
    } catch (error) {
      console.error('Error submitting form', error);
      setSendingStatus('failure');
    }
  };

  const handleClosePopup = () => {
    setSendingStatus(null);
  };

  const confirmSubmit = () => {
    setShowConfirmPopup(true);
    setResetDate(true); // Set to true to reset the date picker

  };

  return (
    <div className="w-full min-h-[90vh] bg-gray-50 p-4">
      <header className="text-center py-4">
        <h1 className="text-4xl font-bold text-primeColor mb-2 after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2">
          {t('withdrawal.header')}
        </h1>
        <p className="text-lg text-gray-700 mt-4">{t('withdrawal.instructions')}</p>
      </header>

      <form className=" w-[80%] mx-auto  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" onSubmit={(e) => e.preventDefault()}>
        
      
        <InputField
          label={t('withdrawal.fullName')}
          name="name"
          type="text"
          placeholder={t('withdrawal.fullName')}
          value={formData.name}
          onChange={handleInputChange}
          width={100}
        />
        <InputField
          label={t('withdrawal.email')}
          name="email"
          type="email"
          placeholder={t('withdrawal.email')}
          value={formData.email}
          onChange={handleInputChange}
          width={100}
        />
        <InputField
          label={t('withdrawal.phone')}
          name="phone"
          type="text"
          placeholder={t('withdrawal.phone')}
          value={formData.phone}
          onChange={handleInputChange}
          width={100}
        />

          <div className='flex col-span-3   gap-[10px]'>
          <MyDatePicker
            holder={t('withdrawal.appointmentDate')}
            onChange={handleDateSelect}
            width={100}
            resetDate={resetDate}

          />

          <MyTimePicker
            holder={t('withdrawal.appointmentTime')}
            onChange={handleTimeSelect}
            dateSelected={formData.appointmentDate}
            width={100}
          />
          </div>
       

        <FileUpload
          file={formData.identityCard}
          label={t('withdrawal.chooseId')}
          fileType="identityCard"
          handleFileUpload={handleFileUpload}
          Icon={FaIdCard}
        />
        <FileUpload
          file={formData.insuranceCard}
          label={t('withdrawal.chooseInsurance')}
          fileType="insuranceCard"
          handleFileUpload={handleFileUpload}
          Icon={FaClipboardCheck}
        />
      </form>

      <TextArea
        label={t('withdrawal.notes')}
        placeholder={t('withdrawal.notes')}
        value={formData.suggestions}
        name="suggestions"
        onChange={handleInputChange}
      />

      <div className="text-center mt-8">
        <button
          className="bg-primeColor text-white font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-secondColor transition-transform duration-300 transform hover:scale-105"
          onClick={confirmSubmit}
        >
          {t('withdrawal.submit')}
        </button>
      </div>

      {showConfirmPopup && (
        <ConfirmationPopup
          onConfirm={handleSubmit}
          onCancel={() => setShowConfirmPopup(false)}
        />
      )}

      {sendingStatus && (
        <SendingStatusPopup
          status={sendingStatus}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Withdrawl;
