import React from 'react';
import { useTranslation } from 'react-i18next';
import SocialSection from '../../component/contact/SocialSection';
import ContactForm from '../../component/contact/ContactForm';
import { FaFacebookF, FaInstagram, FaPhone, FaWhatsapp, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './Contact.scss';


const socialLinks = [
  { icon: <FaInstagram />, text: 'instagram', link: 'https://www.instagram.com/alshifa.lab/' },
  { icon: <FaFacebookF />, text: 'facebook', link: 'https://www.facebook.com/profile.php?id=100085742225186&mibextid=ZbWKwL' },
  { icon: <FaWhatsapp />, text: 'whatsapp', link: 'https://api.whatsapp.com/send/?phone=971501344319&text&type=phone_number&app_absent=0' },
  { icon: <FaEnvelope />, text: 'email', email: 'asmc.lab@gmail.com', link: 'mailto:asmc.lab@gmail.com' },
  { icon: <FaPhone />, text: 'phone', phoneNumber: '0501344319' },
  { icon: <FaPhone />, text: 'phone', phoneNumber: '0569972730' },
  { icon: <FaMapMarkerAlt />, text: 'address', location: 'Sharjah, Khorfakkan', link: "https://www.google.com/maps/place/Alshifa+medical+laboratory/@25.3429584,56.3490257,17z/data=!3m1!4b1!4m6!3m5!1s0x3ef44f82e0f0798d:0xcbf6eba9f41c3fcb!8m2!3d25.3429584!4d56.3490257!16s%2Fg%2F11kj_8l5q_?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D" },
];


const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar'; // Check if the language is Arabic

  return (
    <div className={`min-h-screen p-4 bg-gray-50 ${isRTL ? 'rtl' : 'ltr'}`}>
      {/* Header */}
      <header className="text-center py-6">
        <h1 className="text-4xl font-bold text-primeColor mb-2">{t('contact.header')}</h1>
        <div className="w-1/2 mx-auto after:block after:w-[150px] after:h-1 after:bg-secondColor after:mx-auto after:mt-2" />
      </header>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row justify-between items-center p-6 space-y-4 lg:space-y-0">
        
        {/* Contact Form */}
        <div className="w-full lg:w-1/2 p-4">
          <ContactForm />
        </div>
        

        {/* Divider */}
<div className={`hidden lg:flex items-center justify-center mx-4`}>
  {/* Adjust the size of the dividers dynamically */}
  <div className={`border-${isRTL ? 'l' : 'r'}-2 border-gray-400 h-24 ${isRTL ? 'ml-2 w-[80px]' : 'mr-4 w-[120px]'}`}></div>
  <span className="text-2xl font-bold text-secondColor mx-2">
    {isRTL ? 'أو' : 'OR'}
  </span>
  <div className={`border-${isRTL ? 'r' : 'l'}-2 border-gray-400 h-24 ${isRTL ? 'mr-2 w-[120px]' : 'ml-4 w-[80px]'}`}></div>
</div>


        {/* Social Links */}
        <div className="w-full lg:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4 text-primeColor">{t('contact.subHeader')}</h2>
          <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {socialLinks.map((link, index) => (
              <SocialSection
                key={index}
                icon={link.icon}
                text={t(`contact.social.${link.text}`)}
                link={link.link}
                phoneNumber={link.phoneNumber}
                email={link.email}
                location={link.location}
                isAddress={link.text === 'address'}
                language={i18n.language}  // Pass the current language
              />

            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default ContactUs;
