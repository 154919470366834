import React from 'react';
import LocationImage from "../../images/Assets/locaTion2.jpg";

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';

const Request = () => {
  const { t } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <section id="Morehealthcare" className="bg-backgroundColor2 bg-opacity-50 flex item-center justify-center p-8">
      {/* Second Container */}
      <div className="container mx-auto bg-white rounded-3xl shadow-lg p-5 max-w-4xl grid grid-cols-1 lg:grid-cols-2 items-center  gap-8">
        <div className={`  ${isArabic ? 'text-right  text-end' : 'text-left  text-start'}`}>
          <h4 className="font-bold text-primeColor text-2xl py-4">{t('home.other.request.header')}</h4>
          <p className="text-gray-500 text-lg mb-5">
            {t('home.other.request.description')}
          </p>
        </div> 

        <div className="flex flex-col items-center">
          <img className="mb-4 rounded-lg w-[600px] h-[200px] object-cover " src={LocationImage} alt="Location Cover" />
          <Link to="/contact" className="bg-secondColor text-white py-2 px-6 rounded shadow-md hover:bg-opacity-80 transition duration-300">
            {t('home.other.request.button')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Request;
