import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ActivePic = ({ activeIndex, setActiveIndex, handleClick, handleBackClick, opinion }) => {
  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % opinion.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? opinion.length - 1 : prevIndex - 1));
  };

  const activeImage = opinion[activeIndex];

  // Create a new array with the active image at the front
  const reorderedImages = [
    opinion[activeIndex],  // Active image first
    ...opinion.filter((_, index) => index !== activeIndex), // Rest of the images
  ];

  return (
    <div className="relative w-full p-6 bg-white rounded-xl shadow-lg border border-gray-200">
      {/* Display the active image */}
      <div className="relative h-56 md:h-96 overflow-hidden rounded-lg">
        <img
          src={activeImage.image}
          className="absolute block shadow-lg h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-transform duration-300 transform hover:scale-105"
          alt={`Image by ${activeImage.altText}`} // Set alt text to image title
        />

        {/* Carousel control - Previous button */}
        <button
          type="button"
          className="absolute top-1/2 left-4 z-30 flex items-center justify-center w-10 h-10 rounded-full bg-white/70 shadow-md hover:bg-white/100 transform -translate-y-1/2 transition-all duration-200"
          onClick={handlePrev}
        >
          <FaChevronLeft className="w-5 h-5 text-primeColor" />
        </button>

        {/* Carousel control - Next button */}
        <button
          type="button"
          className="absolute top-1/2 right-4 z-30 flex items-center justify-center w-10 h-10 rounded-full bg-white/70 shadow-md hover:bg-white/100 transform -translate-y-1/2 transition-all duration-200"
          onClick={handleNext}
        >
          <FaChevronRight className="w-5 h-5 text-primeColor" />
        </button>
      </div>

      {/* Back button */}
      <button
        type="button"
        className="absolute top-4 left-4 z-30 px-4 py-2 text-white bg-red-800 rounded-md shadow-lg hover:bg-gray-800 transition duration-200"
        onClick={handleBackClick}
      >
        Close
      </button>

      {/* Thumbnail gallery */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6 mt-8">
        {reorderedImages.map((image, index) => (
          <div key={index} className="cursor-pointer rounded-lg overflow-hidden shadow-lg transition-transform duration-300 ease-in-out">
            <img
              className={`h-48 w-full object-cover rounded-lg transition-transform duration-300 ${
                image === activeImage ? 'scale-105 brightness-50 shadow-lg' : 'hover:scale-105 hover:brightness-75'
              }`}
              src={image.image}
              alt={`Thumbnail for ${image.altText}`}
              loading="lazy"
              onClick={() => handleClick(opinion.indexOf(image))} // Get correct index
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivePic;
