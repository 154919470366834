import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRunning, FaRecycle, FaMicroscope } from 'react-icons/fa';


const Info = () => {
  const { t } = useTranslation();
  const features = t('home.info.features', { returnObjects: true });

  // Mapping of icon names to actual icon components
  const iconMap = {
    running: <FaRunning className="text-6xl mb-4 text-secondColor" />,
    recycle: <FaRecycle className="text-6xl mb-4 text-secondColor" />,
    microscope: <FaMicroscope className="text-6xl mb-4 text-secondColor" />
  };

  return (
    <div className="bg-white py-10">
      <div className="container mx-auto px-4">
        {/* Grid layout for the features */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-col items-center p-4 border rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              {iconMap[feature.icon]} {/* Render the corresponding icon */}
              <h3 className="text-xl font-bold mb-2 text-primeColor">{feature.title}</h3>
              <p className="text-gray-500">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Info;
